import { useState, useEffect } from 'react'
// context
import { useTournamentContext } from '@src/components/tournament/TournamentProvider';
// types
import { tournamentStageNumToName, checkIfStageActive } from "@src/firestore/tournaments";
// components
import StageMaps from './StageMaps';
import { useLeaderboardContext } from '../LeaderboardProvider';

const Maps = () => {
  const { tournament } = useTournamentContext();

  const { leaderboardStages } = useLeaderboardContext();
  const leaderboardStagesSorted = leaderboardStages.sort((a, b) => a.stageNum - b.stageNum);

  const [currentStage, setCurrentStage] = useState<number>(0);

  useEffect(() => {
    if (tournament && tournament.activeStage !== -1) {
      setCurrentStage(tournament.activeStage);
    }
  }, [tournament]);

  return (
    <div className="px-4 sm:px-8 lg:px-12 mt-10">
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase">
          Maps
        </h3>
        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            {leaderboardStagesSorted.map((stage) => (
              <button key={`maps-stage-${stage.stageNum}`} type="button"
                      onClick={() => setCurrentStage(stage.stageNum)}
                      className={`p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                                  ${currentStage === stage.stageNum ? `bg-lightGray ${tournament && checkIfStageActive(stage.stageNum, tournament) ? 'text-green' : 'text-white/85'}`
                                  : `hover:bg-lightGray/20 ${tournament && checkIfStageActive(stage.stageNum, tournament) ? 'text-green/70 hover:text-green' : 'text-steelGray hover:border-steelGray'}`}`}>
                {tournamentStageNumToName(tournament, stage.stageNum)}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full'>
          {leaderboardStagesSorted.map((stage) => (
            <div key={`maps-stage-view-${stage.stageNum}`}>
              {currentStage === stage.stageNum ? (
                <StageMaps stage={stage} />
              ) : ''}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Maps;
