import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
// context
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
// types
import { TeamEditModalStep } from "../../../types";
// components
import Modal from "@src/components/ui/Modal";
import Input from "@src/components/ui/Input";
// icons
import { FaCheck } from "react-icons/fa";
import { FaPaypal } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";

const Step3 = () => {
  const { changeModalStep, currentEditModalStep, setCurrentEditModalStep, teamInfo, setTeamInfo, updateTeam, closeModal } = useTeamInfoContext();

  const [formFilled, setFormFilled] = useState(false);
  const [paypalValid, setPaypalValid] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const validatePaypal = useCallback(() => {
    const paypalRegex = /^(((https|http):\/\/)?(www\.)?paypal\.me\/[a-zA-Z0-9]{1,20}|\w+@\w+\.\w+)$/i
    setPaypalValid(teamInfo.paypalLink === '' || paypalRegex.test(teamInfo.paypalLink));
  }, [teamInfo]);

  useEffect(() => {
    validatePaypal();
  }, [teamInfo, validatePaypal])

  const handleStepSubmit = () => {
    handleTeamUpdate();
  };

  const validateForm = useCallback(async () => {
    const fieldsValid = teamInfo.paypalLink !== '' && paypalValid;
    setFormFilled(fieldsValid);
  }, [teamInfo, paypalValid]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleTeamUpdate = async () => {
    setSubmitting(true); // state to track whether form currently submitting
    const teamUpdated: boolean = await updateTeam();
    if (teamUpdated) {
      closeModal();
    }
    setSubmitting(false);
  }

  return (
    <Modal title='Edit Team'
          step={3}
          totalSteps={3}
          scroll={true}
          stepNames={['General', 'Details', 'Payment']}
          onBackClick={() => changeModalStep(-1)}
          buttonText={(
            <div className="flex items-center gap-x-2">
              <p>Confirm</p>
              {submitting ? (
                <ImSpinner8 className="-translate-y-[1px] animate-spin"/>
              ) : (
                <FaCheck className="-translate-y-[1px]"/>
              )}
            </div>
          )}
          buttonDisabled={submitting || !formFilled}
          buttonOnClick={handleStepSubmit}
          open={currentEditModalStep === TeamEditModalStep.payment}
          setOpen={(open: boolean) => {
            setCurrentEditModalStep(open ? TeamEditModalStep.payment : -1);
          }}>
      <div className="w-full h-fit relative">
      <FaPaypal className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[50%] w-[250px] h-auto aspect-square text-lightGray/40"/>

        <div className="px-6 relative z-[2]">
          <Input label="PayPal.me / PayPal Email *"
                valid={paypalValid}
                locked={teamInfo.tournamentsInPlay.length > 0}
                value={teamInfo.paypalLink}
                onChange={(newValue) => setTeamInfo({...teamInfo, paypalLink: newValue})}
                black={true}/>
          <div className="mt-2">
            {teamInfo.tournamentsInPlay.length === 0 ? (
              <>
                <Link to="https://www.paypal.com/tc/webapps/mpp/paypal-me" target="_blank"
                      className="text-white hover:opacity-75 font-compact font-thin text-sm transition-opacity">
                  <span className="underline">
                    Learn more about {' '}
                  </span>
                  <span className="text-green underline">
                    PayPal.me
                  </span>
                </Link>
                <p className="text-red/90 font-compact font-thin text-sm max-w-[80%] mx-auto mt-2">
                  <span>
                  Versus is not liable for unpaid winnings due to incorrect payment information.
                  </span>
                </p>
              </>
            ) : (
              <p className="text-red/90 font-compact font-thin text-sm max-w-[80%] mx-auto">
              <span>
                You cannot change your payment information while engaged in a tournament
              </span>
            </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Step3;
