// icons
import NoticeText from "@src/components/ui/NoticeText";

const Games = () => {

  return (
    <div className="mt-3">
      <NoticeText>
      Coming Soon
      </NoticeText>
    </div>
  );
};

export default Games;
