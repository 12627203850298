import { useState } from 'react';
// types
import { ManageTeamSubView } from '@src/components/team/types';
// subviews
import Members from './subViews/Members';
import Games from './subViews/Games';
import Sponsors from './subViews/Sponsors';
import Settings from './subViews/Settings';

const ManageTeam = () => {
  const [currentSubView, setCurrentSubView] = useState<ManageTeamSubView>(ManageTeamSubView.members);

  return (
    <div className='flex flex-col gap-y-2 mt-2'>
      <div className='flex items-start gap-x-4'>
        <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
          <span>Manage Team</span>
        </h2>
        <div className="flex items-center gap-x-2 -translate-y-[5px]">
          <button type="button"
                  onClick={() => setCurrentSubView(ManageTeamSubView.members)}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === ManageTeamSubView.members ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            Members
          </button>
          <button type="button"
                  onClick={() => setCurrentSubView(ManageTeamSubView.games)}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === ManageTeamSubView.games ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            Games
          </button>
          <button type="button"
                  onClick={() => setCurrentSubView(ManageTeamSubView.sponsors)}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === ManageTeamSubView.sponsors ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            Sponsors
          </button>
          <button type="button"
                  onClick={() => setCurrentSubView(ManageTeamSubView.settings)}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === ManageTeamSubView.settings ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            Settings
          </button>
        </div>
      </div>

      <div className={`${currentSubView === ManageTeamSubView.members ? '' : 'hidden'}`}>
        <Members/>
      </div>
      <div className={`${currentSubView === ManageTeamSubView.games ? '' : 'hidden'}`}>
        <Games/>
      </div>
      <div className={`${currentSubView === ManageTeamSubView.sponsors ? '' : 'hidden'}`}>
        <Sponsors/>
      </div>
      <div className={`${currentSubView === ManageTeamSubView.settings ? '' : 'hidden'}`}>
        <Settings/>
      </div>
    </div>
  );
};

export default ManageTeam;
