import { useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc} from 'firebase/firestore';
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useAuthContext } from '@src/provider/AuthContextProvider';
// types
import { TournamentStatus } from "@src/firestore/tournaments";
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@src/components/ui/Modal';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

const Winnings = () => {
  const { userObj } = useAuthContext();
  const { tournament } = useTournamentContext();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const markWinningsDistributed = async () => {
    setSubmitting(true);
    if (tournament && tournament.status === TournamentStatus.results && !tournament.completed && userObj && userObj.admin) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentUpdatePromise = updateDoc(tournamentRef, {
        winningsDistributed: true
      })

      toast.promise(tournamentUpdatePromise, {
        pending: 'Marking event complete',
        success: 'Event marked as complete',
        error: 'Error marking event complete'
      })
      await tournamentUpdatePromise;
    }
    setSubmitting(false);
    setConfirmOpen(false);
  }

  return tournament ? (
    <div>
      <button type="button"
              disabled={tournament.status !== TournamentStatus.results || tournament.winningsDistributed}
              onClick={() => setConfirmOpen(true)}
              className="flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                        hover:bg-gorse transition-colors disabled:opacity-50 disabled:hover:bg-green">

        <span>
          {tournament.winningsDistributed ? 'Winnings Distributed' : 'Mark Winnings Distributed'}
        </span>
        {tournament.winningsDistributed ? (
          <FaCheck/>
        ) : submitting ? (
          <ImSpinner8 className='animate-spin'/>
        ) : ''}
      </button>
      {tournament.status < TournamentStatus.results ? (
        <p className={`text-sm font-compact my-2 text-red`}>
          Cannot mark winnings as distributed until results are ready
        </p>
      ) : ''}
      <Modal title='Winnings Distributed'
             open={confirmOpen}
             setOpen={setConfirmOpen}
             buttonDisabled={tournament === null || tournament.status !== TournamentStatus.results || tournament.winningsDistributed}
             buttonText={(
              <div className='flex items-center gap-x-2'>
                <span>
                  Confirm
                </span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
             )}
             buttonOnClick={markWinningsDistributed}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
              <em className="not-italic text-red/70 uppercase"> WARNING! </em>
              Marking winnings as distributed cannot be undone.
            </p>
            <p>
              By confirming you acknowledge that you have distributed payments to all winning parties.
            </p>
            <p>
              Are you sure?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  ) : '';
}

export default Winnings;
