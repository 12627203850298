import { useState } from 'react';
import { Link } from 'react-router-dom';
// context
import { useTeamContext } from '../../../TeamShowProvider';
import { useTeamInfoContext } from '@src/components/team/TeamInfoProvider';
// types
import { DBTeam } from '@src/firestore/teams';
// utils
import { copyDiscordUsername } from "@utils/Socials";
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.png';
// icons
import { DiscordIcon, TwitterIcon, TwitchIcon, InstagramIcon, YouTubeIcon } from "@icons/Socials";

interface ILeaveTeam {
  team: DBTeam,
  leaveTeam: () => void,
}

const LeaveTeam: React.FC<ILeaveTeam> = ({team, leaveTeam}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const inPlay = team.tournamentsInPlay.length > 0;

  return (
    <div className="flex items-center gap-x-3 w-full mt-2">
      {confirm ? (
        <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
          <div className="flex items-center gap-x-2 w-full">
            <button type="button"
                    onClick={() => leaveTeam()}
                    className="w-3/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center  text-red/70 bg-red/20 hover:opacity-75 transition-opacity">
              Confirm
            </button>
            <button type="button"
                    onClick={() => setConfirm(false)}
                    className="w-1/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          {inPlay ? (
            <p className="font-medium font-compact text-red/70 mb-2">
              Cannot leave team while it is engaged in a tournament.
            </p>
          ) : ''}
          <button type="button"
                  disabled={inPlay}
                  onClick={() => setConfirm(true)}
                  className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity w-full
                            disabled:opacity-50 disabled:pointer-events-none">
            Leave Team
          </button>
        </div>
      )}
    </div>
  );
}

const Members = () => {
  const { team, editor } = useTeamContext();
  const { leaveTeam, teamMember} = useTeamInfoContext();

  return team ? (
    <div className='flex flex-col gap-y-2 mt-2'>
      <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
        <span>Team Members</span>
      </h2>
      <div className='flex flex-col'>
        {team.playerData.map((player, index) => (
          <div key={index}>
            <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
              <div
                className={`flex justify-between items-center`}>
                  <Link to={`/profile/${player.id}`}
                        className="flex items-center gap-x-4 hover:opacity-75 transition-opacity">
                    <img
                      className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                      src={player.displayImage ? player.displayImage : imagePlaceholder}
                      alt="image"
                    />
                    <div className="flex flex-col items-start w-full gap-y-1">
                      <div className="flex items-center gap-x-2">
                        <h2 className="font-wide text-left font-bold text-white leading-4">
                          {player.displayName}
                        </h2>
                        <p className={`font-compact font-semibold bg-ebonyClay px-2 py-[0.1rem] pb-[0.07rem] text-xs mb-[2px] rounded-full
                                      ${player.id === team.captain ? "text-green" : "text-slateGray"}`}>
                          {player.id === team.captain ? 'Owner' : 'Player'}
                        </p>
                      </div>
                      <h3 className="font-compact text-sm text-steelGray">
                        @{player.username}
                      </h3>
                    </div>
                  </Link>
                  <div className="flex items-center gap-x-4">
                    <div className='flex items-center gap-x-3'>
                      {player.discord ? (
                          <button type="button" aria-label="copy discord username"
                                  onClick={() => copyDiscordUsername(player.discord)}>
                            <DiscordIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                          </button>
                        ) : ''}
                      {player.twitter ? (
                        <a href={player.twitter} target="_blank">
                          <TwitterIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.twitch ? (
                        <a href={player.twitch} target="_blank">
                          <TwitchIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.youtube ? (
                        <a href={player.youtube} target="_blank">
                          <YouTubeIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.instagram ? (
                        <a href={player.instagram} target="_blank">
                          <InstagramIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        ))}
        {teamMember && !editor ? (
          <LeaveTeam team={team} leaveTeam={leaveTeam}/>
        ) : ''}
      </div>
    </div>
  ): '';
};

export default Members;
