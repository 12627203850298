import { useState } from "react";
// context
import { useAuthContext } from "@src/provider/AuthContextProvider";
import { useTeamContext } from "@components/team/TeamShowProvider";
import { useTeamInfoContext } from "../../TeamInfoProvider";
// types
import { TeamEditModalStep } from "@components/team/types";
import { TournamentTrophy, trophyPositionToString } from "@src/firestore/tournamentTrophies";
// utils
import { formatDate } from "@utils/Date";
// libraries
import { toast } from "react-toastify";
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.png';
// components
import PopoverText from "@src/components/ui/PopoverText";
// icons
import { EditProfileBtn } from "@icons/ProfileIcons";
import { DiscordIcon, TwitterIcon, TwitchIcon, WebsiteIcon } from "@icons/Socials";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from "@icons/Games";
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from "@icons/TournamentIcons";
import { CupNumbering } from "@icons/ProfileIcons";
import { LocationIcon, MagnifyingGlassIcon } from "@icons/Common";
import { FaLink, FaPaypal } from "react-icons/fa";

const getCupIconForTrophy = (finalPosition: number) => {
  switch (finalPosition) {
    case 1:
      return <TrophyGoldImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    case 2:
      return <TrophySilverImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    case 3:
      return <TrophyBronzeImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    default:
      return '';
  }
}

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon'
};

const gameNameToSmallIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[16px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors"/>);
    default:
      return '';
  }
}

interface IHero {
  className?: string
}

const Hero: React.FC<IHero> = ({ className }) => {
  const { team, teamTrophies, editor } = useTeamContext();
  const { setCurrentEditModalStep } = useTeamInfoContext();
  const { userObj } = useAuthContext();

  // const [winningsToolTip, setWinningsToolTip] = useState<boolean>(false);
  const [hoveredTrophyGroup, setHoveredTrophyGroup] = useState<number | null>(null);

  const noSocials = team !== null && !team.discordLink && !team.twitterLink && !team.twitchLink && !team.websiteLink;

  const formatWinningsToString = (winnings: number) => {
    const winningsString = winnings.toString();
    return `$${winningsString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  const totalTeamWinnings = teamTrophies.reduce((acc, curr) => acc + Math.round(curr.winnings), 0) ?? 0;

  const trophiesCount: { [key: string]: TournamentTrophy[] } = {
    '1': [],
    '2': [],
    '3': [],
  };

  teamTrophies.forEach((trophy) => {
    const finalPositionString = trophy.position.toString();
    trophiesCount[finalPositionString].push(trophy);
  })

  const copyTeamLinkToClipboard = () => {
    if (team) {
      toast.info('Team link copied to clipboard')
      navigator.clipboard.writeText(`https://versusgaming.gg/team/${team.id}`)
    }
  }

  const copyDiscordUsername = (username: string) => {
    toast.info('Discord username copied to clipboard');
    navigator.clipboard.writeText(username);
  }

  if (team) return (
    <div className={`${className}`}>
      {/* Display the profile header */}
      <div className="relative sm:rounded-2xl bg-lightBlack flex flex-col">
        <div className="absolute top-3 right-3 flex items-center gap-x-2">
          {editor ? (
            <button type='button'
                    onClick={() => setCurrentEditModalStep(TeamEditModalStep.profile)}
                    className="bg-lightGray hover:bg-ebonyClay duration-200 font-compact h-[40px] uppercase font-medium px-6 rounded-xl flex gap-2 items-center !leading-4
                                border border-steelGray hover:border-green">
              <span className="hidden sm:block">Edit Team</span>
              <EditProfileBtn />
            </button>
          ) : ''}
          <button type="button"
                  aria-label="copy team link to clipboard"
                  onClick={copyTeamLinkToClipboard}
                  className="bg-lightGray hover:bg-ebonyClay duration-200 h-[40px] !leading-4 uppercase font-medium px-6 rounded-xl font-compact text-white
                             flex items-center gap-x-2 border border-steelGray hover:border-green">
            <FaLink className="w-[16px] h-auto aspect-square fill-green"/>
          </button>
        </div>
        {/* Cover image - desktop view */}
        <img
          className="sm:rounded-t-2xl w-full h-auto aspect-[3.15] object-cover hidden sm:block"
          src={team.bannerUrl ? team.bannerUrl : imagePlaceholder}
          loading="lazy"
          alt="coverImg"
        />
        {/* Cover image - mobile view */}
        <img
          className={`w-full h-auto aspect-[3.15] object-cover object-center sm:hidden cursor-pointer`}
          src={team.bannerUrl ? team.bannerUrl : imagePlaceholder}
          loading="lazy"
          alt="coverImg"
        />
        {/* Profile information */}
        <div className="p-4 sm:p-6 lg:p-8 flex justify-between items-end relative lg:mt-0 flex-grow gap-x-4 xl:gap-x-8">
          <div className="flex gap-1 sm:gap-2 absolute top-0 right-2 sm:right-8 -translate-y-1/2 justify-end lg:justify-start mb-5 lg:mb-0  z-[9]">
            {Object.entries(trophiesCount).map((entry, groupIndex) => {
              const finalPosition = parseInt(entry[0]) as number;
              const finalPositionTrophies = entry[1];
              const count = finalPositionTrophies.length;
              if (count > 0) {
                return (
                  <span key={`group-${groupIndex}`}
                        className="relative"
                        onMouseOver={() => setHoveredTrophyGroup(groupIndex)}
                        onMouseOut={() => setHoveredTrophyGroup(null)}
                        >
                    {getCupIconForTrophy(finalPosition)}
                    <CupNumbering count={count}/>
                    <ul className={`${hoveredTrophyGroup === groupIndex ? '' : 'opacity-0'} transition-opacity pointer-events-none absolute w-[200px] bottom-0 right-0 translate-y-full
                                  p-2 rounded-lg bg-lightBlack/70 backdrop-blur-md text-steelGray border border-steelGray/50 font-medium  flex flex-col gap-y-3`}>
                      {finalPositionTrophies.map((trophy, trophyIndex) => (
                        <li key={`${groupIndex}-${trophyIndex}`}
                            className="w-full font-compact">
                          <div>
                            <p className="text-white/80">{trophy.tournamentName}</p>
                            <div className="flex items-center gap-x-2">
                              <p className="text-green">{trophyPositionToString(trophy.position)}</p>
                              <p className="text-steelGray text-sm font-thin">
                                - {trophy.teamName}
                              </p>
                            </div>
                            <p className="text-sm opacity-75">{formatDate(trophy.dateRecieved)}</p>
                          </div>
                          {trophyIndex !== finalPositionTrophies.length - 1 ? (
                            <div className="w-full h-[1px] mt-3 bg-steelGray/50"></div>
                          ) : ''}
                        </li>
                      ))}
                    </ul>
                  </span>
                )
              }
            })}
          </div>

          <div className="absolute lg:relative top-0 left-4 lg:left-0 -translate-y-1/3 lg:-translate-y-0 w-fit h-fit flex-shrink-0">
            <img src={team.logoUrl ? team.logoUrl : imagePlaceholder} alt="profileImg"
                className={`w-[8rem] h-[8rem] sm:w-[10rem] sm:h-[10rem] lg:w-[11.55rem] lg:h-[11.55rem]
                border-[4px] border-lightBlack object-cover rounded-2xl`}/>
          </div>

          <div className="relative flex flex-col lg:flex-row gap-6 xl:gap-8 w-full pt-[7rem] lg:pt-0">
            {team ? (
              <div className="flex sm:hidden flex-col gap-y-4 absolute top-[25px] right-0 ">
                <div className="flex items-center gap-x-2">
                  <p className={`text-white totalTeamWinnings text-lg font-wide !leading-4 uppercase font-bold items-center gap-2
                    justify-end -mb-1 flex `}>
                    <span>
                      Winnings:
                    </span>
                    <span className="text-green">
                      {formatWinningsToString(totalTeamWinnings)}
                    </span>
                  </p>
                </div>

                <div className="flex self-end items-center gap-x-2">
                  <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6
                                  hover:opacity-85 transition-all'>
                    <p className='text-white font-compact text-sm whitespace-nowrap'>Match Wins</p>
                    <p className='text-green font-wide font-semibold'>{team.apexMatchNum > 0 ? team.apexMatchWins : 'N/A' }</p>
                  </div>

                  <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6
                                  hover:opacity-85 transition-all'>
                    <p className='text-white font-compact text-sm whitespace-nowrap'>Win Rate</p>
                    <p className='text-green font-wide font-semibold'>{team.apexMatchNum > 0 ? `${team.apexMatchWinrate}%` : 'N/A' }</p>
                  </div>
                </div>
              </div>
            ) : ''}

            {/* Profile information */}
            <div className="flex flex-col justify-between flex-grow min-h-[120px] h-[120px] sm:h-auto gap-y-4">
              <p className="px-3 py-[0.4rem] sm:py-[0.45rem] !leading-4 bg-lightGray rounded-[3rem] flex items-center gap-2 text-sm font-compact text-white w-fit">
                {gameNameToIcon(team.mainGame)}
                <span className="-mb-0.5 whitespace-nowrap">{team.mainGame}</span>
              </p>
              <div className="flex justify-between w-full h-full">
                <div className={`flex flex-col gap-y-4 ${noSocials ? '' : 'self-end'}`}>
                  <div className="flex flex-col">
                    <h5 className={`flex items-center gap-x-2 text-white font-bold !leading-[100%]`}>
                      <p className="font-wide text-3xl sm:text-2xl">{team.teamName}</p>
                    </h5>
                    <div className="flex items-center gap-x-3">
                      <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 transition-colors">{team.teamTag.toUpperCase()}</p>
                      <div className="relative group/game h-fit w-fit">
                        {gameNameToSmallIcon(team.mainGame)}
                        <PopoverText className="group-hover/game:opacity-100" visible={false}>
                          {team.mainGame}
                        </PopoverText>
                      </div>
                      <div className="flex items-center gap-x-1">
                        <LocationIcon className="w-[13px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors"/>
                        <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 transition-colors">{team.region.toUpperCase()}</p>
                      </div>
                      {team.lookingForPlayers ? (
                        <div className="relative group">
                          <PopoverText visible={true} className="opacity-0 group-hover:opacity-100 transition-opacity">Looking for Players</PopoverText>
                          <MagnifyingGlassIcon className="w-[12px] h-auto aspect-square stroke-green"/>
                        </div>
                      ) : ''}
                    </div>
                  </div>

                  {team.discordLink || team.twitterLink || team.twitchLink || team.websiteLink ? (
                    <div className="hidden lg:flex gap-2">
                      {team.discordLink ? (
                        <button type="button" aria-label="copy discord username"
                                onClick={() => copyDiscordUsername(team.discordLink)}>
                          <DiscordIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </button>
                      ) : ''}
                      {team.twitterLink ? (
                        <a href={team.twitterLink} target="_blank">
                          <TwitterIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {team.twitchLink ? (
                        <a href={team.twitchLink} target="_blank">
                          <TwitchIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {team.websiteLink ? (
                        <a href={team.websiteLink} target="_blank">
                          <WebsiteIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {userObj && userObj.admin ? (
                        <button type='button'
                                onClick={() => {
                                  toast.info('Team paypal link copied to clipboard')
                                  navigator.clipboard.writeText(team.paypalLink);
                                }}
                                className="bg-[#F7F7F7] hover:opacity-50 duration-200 font-compact w-[35px] h-auto aspect-square uppercase font-medium rounded-xl flex justify-center items-center !leading-4">
                          <FaPaypal className="text-2xl text-[#002E83]"/>
                        </button>
                      ) : ''}
                    </div>
                  ) : ''}
                </div>
                <div className="second flex flex-col justify-end">
                {team ? (
                  <div className="hidden sm:flex flex-col gap-6 relative self-end">
                    <p className={`text-white totalTeamWinnings text-2xl font-wide !leading-4 uppercase font-bold items-center gap-2
                      justify-end -mb-1 flex`}>
                      <span>
                        Winnings:
                      </span>
                      <span className="text-green">
                        {formatWinningsToString(totalTeamWinnings)}
                      </span>
                    </p>

                    <div className="flex self-end items-center gap-x-2">
                      <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6 tr                                   hover:opacity-85 transition-all'>
                        <p className='text-white font-compact text-sm whitespace-nowrap'>Match Wins</p>
                        <p className='text-green font-wide font-semibold'>{team.apexMatchNum > 0 ? team.apexMatchWins : 'N/A' }</p>
                      </div>

                      <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6 tr                                   hover:opacity-85 transition-all'>
                        <p className='text-white font-compact text-sm whitespace-nowrap'>Win Rate</p>
                        <p className='text-green font-wide font-semibold'>{team.apexMatchNum > 0 ? `${team.apexMatchWinrate}%` : 'N/A' }</p>
                      </div>
                    </div>
                  </div>
                ) : ''}
                {/* Social icons - mobile view */}
                <div className={`justify-end lg:hidden gap-2 pt-6 flex`}>
                  {team.discordLink ? (
                    <button type="button" aria-label="copy discord username"
                            onClick={() => copyDiscordUsername(team.discordLink)}>
                      <DiscordIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </button>
                  ) : ''}
                  {team.twitterLink ? (
                    <a href={team.twitterLink} target="_blank">
                      <TwitterIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {team.twitchLink ? (
                    <a href={team.twitchLink} target="_blank">
                      <TwitchIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {team.websiteLink ? (
                    <a href={team.websiteLink} target="_blank">
                      <WebsiteIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {userObj && userObj.admin ? (
                  <button type='button'
                          onClick={() => {
                            toast.info('Team paypal link copied to clipboard')
                            navigator.clipboard.writeText(team.paypalLink);
                          }}
                          className="bg-[#F7F7F7] hover:opacity-50 duration-200 font-compact w-[45px] sm:w-[35px] h-auto aspect-square uppercase font-medium rounded-xl flex justify-center items-center !leading-4">
                    <FaPaypal className="text-3xl sm:text-2xl text-[#002E83]"/>
                  </button>
                  ) : ''}
                </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
