import { Link } from "react-router-dom";
// types
import { DBTeam } from '@src/firestore/teams';
// packages
import "/node_modules/flag-icons/css/flag-icons.min.css";
// components
import PopoverText from "@src/components/ui/PopoverText";
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.png';
// icons
import { LocationIcon, MagnifyingGlassIcon } from '@icons/Common';
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from '@icons/TournamentIcons';
import { ApexLegendsIcon } from '@icons/Games';

interface IHeroSlideInner {
  team: DBTeam
}

const HeroSlideInner: React.FC<IHeroSlideInner> = ({ team }) => {
  const teamTrophies = team.trophyData;

  const totalTeamWinnings = teamTrophies.reduce((acc, curr) => acc + Math.round(curr.winnings), 0) ?? 0;
  const goldTrophies = teamTrophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = teamTrophies.filter((trophy) => trophy.position === 2);
  const bronzeTrophies = teamTrophies.filter((trophy) => trophy.position === 3);

  return (
    <Link to={`/team/${team.id}`}
          className="slider_cards_overlay bg-lightBlack rounded-lg overflow-hidden block w-full h-auto md:aspect-[4.2]">
      <div className="flex flex-col-reverse md:flex-row flex-wrap justify-between items-center md:items-center h-full">
        <div className="w-11/12 md:w-[44%] h-full py-8 md:py-0 flex flex-col justify-center lg:w-[32%] bg-lightBlack z-10 relative md:bg-none rounded-lg mt-[-6.25rem] md:mt-0 md:mx-0">
          <div className="relative flex items-center gap-x-10 z-[2] w-fit ml-8">
            <div className="relative z-[2] h-fit w-fit flex-shrink-0">
              <img src={team.logoUrl} alt={`${team.logoUrl} display image`}
                  className='w-[80px] lg:w-[100px] xl:w-[120px] h-auto aspect-square rounded-3xl border-[3px] border-lightBlack object-cover  flex-shrink-0'/>
            </div>

            <div className='flex flex-col gap-y-2'>
              <div className="flex items-center gap-x-2">
                <h3 className='font-wide font-semibold uppercase text-white text-2xl lg:text-[1.6rem]'>{team.teamName}</h3>
                {/* <img src={team.logoUrl} alt={`${team.teamName} logo`}
                  className='hidden lg:block mb-1 w-[25px] h-auto aspect-square rounded border border-steelGray group-hover:border-white/65 object-cover transition-colors'/> */}
              </div>
              <div className='flex items-center gap-x-2'>
                <p className="text-steelGray font-compact font-medium -mb-1 xl:text-lg">{team.teamTag.toUpperCase()}</p>
                <ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-steelGray"/>
                <div className="flex items-center gap-x-1">
                  <LocationIcon className="w-[15px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors"/>
                  <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 xl:text-lg transition-colors">{team.region.toUpperCase()}</p>
                </div>
                {team.lookingForPlayers ? (
                  <div className="relative group/lfp">
                    <PopoverText visible={true} className="opacity-0 group-hover/lfp:opacity-100 transition-opacity">Looking for Players</PopoverText>
                    <MagnifyingGlassIcon className="w-[14px] h-auto aspect-square stroke-green"/>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>

          <div className="w-[calc(100%-4rem)] h-[2px] mx-auto bg-lightGray my-4 lg:my-6"></div>

          <div className="flex flex-col gap-y-4 w-fit ml-8">

            <div className='flex items-center'>
              <div className='flex items-center gap-x-1'>

                <div className="relative w-fit h-fit">
                  <div className={`${goldTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{goldTrophies.length}</p>
                  </div>
                  <TrophyGoldImage className={`w-[54px] h-auto aspect-square object-contain ${goldTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

                <div className="relative w-fit h-fit">
                  <div className={`${silverTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{silverTrophies.length}</p>
                  </div>
                  <TrophySilverImage className={`w-[54px] h-auto aspect-square object-contain ${silverTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

                <div className="relative w-fit h-fit">
                  <div className={`${bronzeTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{bronzeTrophies.length}</p>
                  </div>
                  <TrophyBronzeImage className={`w-[54px] h-auto aspect-square object-contain ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

              </div>
              <div className="h-[35px] w-[2px] bg-lightGray ml-2 mr-4"></div>
              <div className='mt-1 xl:text-lg'>
                <p className="font-wide font-semibold text-white uppercase">Winnings</p>
                <p className="font-wide font-semibold text-green uppercase">{totalTeamWinnings > 0 ? `$${totalTeamWinnings}` : 'N/A'}</p>
              </div>
            </div>

            <div className='hidden custom_xl:flex items-center gap-x-2'>
              {team.playerData.slice(0, 5).map((player, index) => (
                <img key={`${team.id}-${index}-${player.id}-image`} src={player.displayImage !== '' ? player.displayImage : placeholderImage} alt={`${player.displayName} display image`}
                     className='w-[40px] h-auto aspect-square rounded-full'/>
              ))}
              {team.playerData.length > 5 ? (
                <>
                  <div className='h-[35px] w-[2px] bg-lightGray group-hover:bg-ebonyClay'></div>
                  <div className="w-[40px] h-auto aspect-square rounded-full bg-ebonyClay group-hover:bg-ebonyClay
                                  flex items-center justify-center">
                    <p className='font-wide font-semibold text-steelGray text-lg md:text-xl -mb-2'>
                      +{team.playerData.length - 5}
                    </p>
                  </div>
                </>
              ) : ''}
            </div>
          </div>

        </div>
        <div className="h-full md:w-[56%] lg:w-[68%] w-full">
          <img
            className="hidden md:block w-full h-full object-cover object-center xl:object-right"
            src={team.bannerUrl !== '' ? team.bannerUrl : placeholderImage}
            alt="player banner image"
          />
          <img
            className="md:hidden w-full h-[30rem] object-cover object-top"
            src={team.bannerUrl !== '' ? team.bannerUrl : placeholderImage}
            alt="player display image"
          />
        </div>
      </div>
    </Link>
  );
}
export default HeroSlideInner;
