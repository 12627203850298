import { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// context
import { AuthContextProvider } from "./provider/AuthContextProvider";
import { HeaderProvider } from "./provider/HeaderProvider";
import ProfileInfoProvider from "./components/completeProfile/ProfileInfoProvider";
import DateTimeProvider from "./provider/DateTimeProvider";
// --- Components ---
// Global
import Home from "@components/home/Home";
import VersionCheck from "@components/versionCheck/VersionCheck";
import Header from "@components/header/Header";
import Footer from "@components/common/Footer";
// new user onboarding
import CompleteProfile from "@components/completeProfile/CompleteProfile";
// Settings
import Settings from "@components/settings/Settings";
import AccountSettings from "@components/settings/Account/Account";
import NotificationSettings from "@components/settings/Notifications/Notifications";
import GamesSettings from "@components/settings/Games/Games";
// Creators
// import Creators from "@components/creators/Creators";
// News
// import NewsMain from "@components/news/NewsMain";
import NewsDetail from "@components/news-detail/NewsDetailMain";
// Tournaments
import Tournaments from "@components/tournaments/Tournaments";
import Tournament from "@components/tournament/Tournament";
// Teams
import Team from "@src/components/team/show/Team";
import CreateTeam from "@components/team/create/CreateTeam";
import Teams from "@components/teams/Teams";
// Profiles
import Profile from "@components/profile/Profile";
// --- Players list
import Players from "./components/players/Players";
// Info
import AboutUs from "@components/about-us/AboutUs";
import ContactMain from "@components/contact/ContactMain";
import ServicesMain from "@components/services/ServicesMain";
// Terms + Privacy
import Terms from "@components/terms/Terms";
import PrivacyPolicy from "@components/privacy-policy/PrivacyPolicy";
// Misc
import ScrollToTop from "@components/scrollToTop/ScrollToTop";
import ComingSoon from "@components/common/coming-soon/ComingSoon";
import AdminDashboard from "@components/adminDashboard/AdminDashboard";
// Error Pages
import Error from "@components/common/Error";
// toast container
import CustomToastContainer from '@components/toast/toast';
// css
import "./css/about.css";
import "./css/app.css";
import "./css/auth.css";
import "./css/compete.css";
import "./css/creator.css";
import "./css/header.css";
import "./css/homepage.css";
import "./css/news.css";
import "./css/profile.css";
import "./css/tournament.css";
import AnnouncementProvider from './provider/AnnouncementProvider';

const App = () => {
  const [announcementVisible, setAnnouncementVisible] = useState<boolean>(false);

  return (
    <BrowserRouter>
      <VersionCheck/>
      <CustomToastContainer announcementVisible={announcementVisible}/>

      <DateTimeProvider>

        <AuthContextProvider>
          <AnnouncementProvider announceAnnouncementVisible={setAnnouncementVisible}>
            <ProfileInfoProvider>
              <HeaderProvider>
                <ScrollToTop />
                <Header />
                <CompleteProfile />

                <div className={`relative ${announcementVisible ? 'mt-[calc(4.3rem+30px)]' : 'mt-[4.3rem]'} min-h-[calc(90vh-4rem)] md:pt-8`}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/creators" element={<Creators />} /> */}
                    <Route
                      path="/creators"
                      element={<ComingSoon title="creators" />}
                    />
                    <Route
                      path="/creators/:id"
                      element={<ComingSoon title="creator" />}
                    />
                    <Route path="/tournaments" element={<Tournaments />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/services" element={<ServicesMain />} />
                    {/* <Route path="/news" element={<NewsMain />} /> */}
                    <Route path="/news" element={<ComingSoon title="news" />} />
                    <Route path="/news/:id" element={<NewsDetail />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/my-profile" element={<Profile />} />
                    <Route path="/profile/:id_or_name" element={<Profile />} />
                    <Route
                      path="/my-profile/:id"
                      element={<ComingSoon title="my-profile" />}
                    />
                    <Route path="/create-team" element={<CreateTeam />} />
                    {/* my-team paths to be swapped /teams/:id  */}
                    <Route path="/my-team" element={<Team />} />
                    <Route path="/team/:id_or_name" element={<Team />} />
                    <Route path="/teams" element={<Teams />} />
                    <Route path="/tournaments/:id"
                          element={<Tournament />}>
                      <Route
                        path="checkIn"
                        element={<Tournament />}
                      />
                      <Route
                        path="results"
                        element={<Tournament />}
                      />
                    </Route>
                    <Route path="/contact" element={<ContactMain />} />
                    <Route
                      path="/players"
                      element={<Players/>}
                    />
                    <Route path="/settings" element={<Settings />}>
                      <Route path="" element={<AccountSettings />} />
                      <Route path="account" element={<AccountSettings />} />
                      <Route path="notifications" element={<NotificationSettings/>} />
                      <Route path="games" element={<GamesSettings />} />
                    </Route>
                    <Route path="/admin" element={<AdminDashboard />} />
                    <Route path="/*" element={<Error />} />
                  </Routes>
                </div>

                <Footer />

              </HeaderProvider>
            </ProfileInfoProvider>
          </AnnouncementProvider>
        </AuthContextProvider>
      </DateTimeProvider>
    </BrowserRouter>
  );
}
// Export the App component as the default export
export default App;
