export enum TeamSubView {
  performance,
  members,
  transfers,
  stats,
  sponsors,
  manage
}

export enum ManageTeamSubView {
  members,
  games,
  sponsors,
  settings
}

export enum TeamEditModalStep {
  profile,
  region,
  payment,
  sponsors,
  invite
}
