import { useRef, useState, useEffect } from "react";
// firebase
import { firestore } from "../../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
// context
import { useTournamentContext } from "../../TournamentProvider";
// libraries
import { toast } from "react-toastify";
// components
import CheckBox from "@src/components/ui/CheckBox";
// icons
import { FaCheck, FaPen } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { TwitchIcon, YouTubeIcon } from "../../../common/icons/Socials";
import { formatLink } from "../../../../utils/Link";

const LiveBroadcasts = () => {
  const { tournament } = useTournamentContext();

  const twitchLinkInputRef = useRef(null);
  const youtubeLinkInputRef = useRef(null);

  const [twitchLinkEdit, setTwitchLinkEdit] = useState<boolean>(false);
  const [youtubeLinkEdit, setYoutubeLinkEdit] = useState<boolean>(false);

  const [twitchLinkValid, setTwitchLinkValid] = useState<boolean>(false);
  const [youtubeLinkValid, setYoutubeLinkValid] = useState<boolean>(false);


  const handleSaveTwitchLink = async () => {
    if (tournament && twitchLinkInputRef.current) {
      const newValue = (twitchLinkInputRef.current as HTMLInputElement).value;
      const formattedValue = formatLink(newValue);
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentUpdatePromise = updateDoc(tournamentRef, {
        streams: {...tournament.streams, twitch: formattedValue},
      });
      toast.promise(tournamentUpdatePromise, {
        pending: 'Updating twitch stream link',
        success: 'Twitch stream link updated',
        error: 'Error updating twitch stream link'
      })
      await tournamentUpdatePromise;
      setTwitchLinkEdit(false);
    }
  };

  const handleSaveYoutubeLink = async () => {
    if (tournament && youtubeLinkInputRef.current) {
      const newValue = (youtubeLinkInputRef.current as HTMLInputElement).value;
      const formattedValue = formatLink(newValue);
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentUpdatePromise = updateDoc(tournamentRef, {
        streams: {...tournament.streams, youtube: formattedValue},
      });
      toast.promise(tournamentUpdatePromise, {
        pending: 'Updating youtube stream link',
        success: 'Youtube stream link updated',
        error: 'Error updating youtube stream link'
      })
      await tournamentUpdatePromise;
      setYoutubeLinkEdit(false);
    }
  };

  const handleTwitchLinkEdit = () => {
    if (tournament) {
      setTwitchLinkEdit(!twitchLinkEdit);
      if (!twitchLinkEdit) {
        setTimeout(() => {
          if (twitchLinkInputRef.current) {
            (twitchLinkInputRef.current as HTMLInputElement).focus();
            (twitchLinkInputRef.current as HTMLInputElement).value = tournament.streams.twitch;
          }
        }, 50)
      }
    }
  }

  const handleYoutubeLinkEdit = () => {
    if (tournament) {
      setYoutubeLinkEdit(!youtubeLinkEdit);
      if (!youtubeLinkEdit) {
        setTimeout(() => {
          if (youtubeLinkInputRef.current) {
            (youtubeLinkInputRef.current as HTMLInputElement).focus();
            (youtubeLinkInputRef.current as HTMLInputElement).value = tournament.streams.youtube;
          }
        }, 50)
      }
    }
  }

  const handleToggleTwitchStream = async () => {
    if (tournament) {
      if (tournament.streams.twitch === '') {
        toast.error('Cannot enable this stream with no link provided')
        return;
      }
      let activeStream: string;
      if (tournament.streams.activeStream === 'twitch') {
        activeStream = '';
      } else {
        activeStream = 'twitch';
      }
      try {
        const tournamentRef = doc(firestore, 'tournaments', tournament.id);
        const tournamentUpdatePromise = updateDoc(tournamentRef, {
          streams: {...tournament.streams, activeStream: activeStream},
        });
        toast.promise(tournamentUpdatePromise, {
          pending: `${tournament.streams.activeStream === 'twitch' ? 'Disabling' : 'Activating'} twitch stream`,
          success: `Twitch stream ${tournament.streams.activeStream === 'twitch' ? 'disabled' : 'activated'}`,
          error: `Error ${tournament.streams.activeStream === 'twitch' ? 'disabling' : 'enabling'} twitch stream`
        })
        await tournamentUpdatePromise;
      } catch (err) {
        console.error(err);
        toast.error(`Error ${tournament.streams.activeStream === 'twitch' ? 'disabling' : 'enabling'} twitch stream`)
      }
    }
  }

  const handleToggleYoutubeStream = async () => {
    if (tournament) {
      if (tournament.streams.youtube === '') {
        toast.error('Cannot enable this stream with no link provided')
        return;
      }
      let activeStream: string;
      if (tournament.streams.activeStream === 'youtube') {
        activeStream = '';
      } else {
        activeStream = 'youtube';
      }
      try {
        const tournamentRef = doc(firestore, 'tournaments', tournament.id);
        const tournamentUpdatePromise = updateDoc(tournamentRef, {
          streams: {...tournament.streams, activeStream: activeStream},
        });
        toast.promise(tournamentUpdatePromise, {
          pending: `${tournament.streams.activeStream === 'youtube' ? 'Disabling' : 'Activating'} youtube stream`,
          success: `Youtube stream ${tournament.streams.activeStream === 'youtube' ? 'disabled' : 'activated'}`,
          error: `Error ${tournament.streams.activeStream === 'youtube' ? 'disabling' : 'enabling'} youtube stream`
        })
        await tournamentUpdatePromise;
      } catch (err) {
        console.error(err);
        toast.error(`Error ${tournament.streams.activeStream === 'youtube' ? 'disabling' : 'enabling'} youtube stream`)
      }
    }
  }

  const validateTwitchLink = (twitchLink: string) => {
    if (twitchLink) {
      setTwitchLinkValid(/((https|http):\/\/)?(www\.)?(twitch\.tv)\/\w+/i.test(twitchLink));
    } else {
      setTwitchLinkValid(true);
    }
  }

  const validateYoutubeLink = (youtubeLink: string) => {
    if (youtubeLink) {
      setYoutubeLinkValid(/((https|http):\/\/)?(www\.)?(youtube\.com)\/\w+/i.test(youtubeLink));
    } else {
      setYoutubeLinkValid(true);
    }
  }

  useEffect(() => {
    if (tournament) {
      validateYoutubeLink(tournament.streams.youtube)
      validateTwitchLink(tournament.streams.twitch);
    }
  }, [tournament, tournament?.streams.youtube, tournament?.streams.twitch]);

  const [streamsInUse, setStreamsInUse] = useState<boolean>(false);

  const handleToggleNoStreamsInUse = async () => {
    try {
      if (!tournament) throw new Error('tournament not found');
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const localStreamsInUse = !streamsInUse;

      const tournamentPromise = updateDoc(tournamentRef, {
        streams: {
          ...tournament.streams,
          activeStream: localStreamsInUse ? '' : 'none'
        }
      })

      await tournamentPromise;
    } catch (err) {
      toast.error('Error toggling streams');
      console.error('Error toggling streams', err);
    }
  }

  useEffect(() => {
    if (tournament) {
      setStreamsInUse(tournament.streams.activeStream !== 'none');
    }
  }, [tournament]);

  return tournament ? (
    <div className="w-full flex flex-col gap-y-2">
      <button type="button" aria-label="Toggle Streams In Use"
              onClick={handleToggleNoStreamsInUse}
              className="p-2 px-4 rounded-lg bg-lightGray/80 text-white uppercase font-semibold font-wide flex items-center gap-x-4 w-fit
                         hover:opacity-75 transition-opacity">
        <p>Streams in use</p>
        <CheckBox selected={streamsInUse} setSelected={handleToggleNoStreamsInUse} asDiv={true}/>
      </button>
      <div className={`${streamsInUse ? '' : 'opacity-50 pointer-events-none'} flex flex-col gap-y-2`}>
        <div className='w-full flex items-center gap-x-2 h-[45px] text-white'>
          <button type='button'
            onClick={handleToggleTwitchStream}
            className="w-1/4 h-full rounded-xl bg-lightGray/80 flex items-center justify-between px-3">
            <div className="flex items-center gap-x-2">
              <TwitchIcon className="fill-white w-[20px] h-auto aspect-square" />
              <p className="text-white font-wide font-semibold uppercase text-sm lg:text-base hidden md:block">Twitch</p>
            </div>
            <div className={`flex items-center justify-center w-[20px] h-auto aspect-square rounded-lg border border-steelGray text-black
                              ${tournament.streams.activeStream === 'twitch' ? 'bg-green' : ''}`}>
              {tournament.streams.activeStream === 'twitch' ? (
                <FaCheck/>
              ) : ''}
            </div>
          </button>
          <div className={`relative w-1/2 h-full bg-lightGray rounded-xl flex flex-col justify-start px-4 py-1 border-2
                              ${twitchLinkEdit ? `${twitchLinkValid ? 'border-green' : 'border-red'}` : 'border-green/0'}`}>
            <p className={`${twitchLinkEdit ? 'absolute top-0 left-3 -translate-y-1/2 bg-lightGray p-1 px-2' : 'bg-lightGray/0'} font-semibold text-sm rounded transition-all`}>URL</p>
            {twitchLinkEdit ? (
              <input type='text'
                onChange={(e) => validateTwitchLink(e.target.value)}
                ref={twitchLinkInputRef}
                className={`w-full h-full p-2 pl-1 text-white font-compact border-none outline-none bg-transparent opacity-0 ${twitchLinkEdit ? 'opacity-100' : ''}`} />
            ) : (
              <p className='text-steelGray text-sm font-semibold whitespace-nowrap overflow-x-scroll'>{tournament.streams.twitch}</p>
            )}
          </div>
          <div className='w-1/4 h-full font-semibold'>
            {twitchLinkEdit ? (
              <div className='w-full h-full flex items-center'>
                <button type='button'
                  disabled={!twitchLinkValid}
                  onClick={handleSaveTwitchLink}
                  className='w-3/4 h-full bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green transition-all text-black rounded-l-xl uppercase font-bold'>
                  Save
                </button>
                <button type='button'
                  onClick={() => setTwitchLinkEdit(false)}
                  className='flex items-center justify-center w-1/4 h-full bg-red/50 hover:bg-red/70 text-white rounded-r-xl uppercase font-bold'>
                  <IoCloseSharp className='text-3xl' />
                </button>
              </div>
            ) : (
              <button type='button'
                disabled={false}
                onClick={handleTwitchLinkEdit}
                className='flex items-center gap-x-3 w-full h-full justify-center bg-lightGray rounded-xl uppercase  hover:opacity-75 disabled:opacity-50 transition-opacity text-sm sm:text-base'>
                <span>Edit URL</span>
                <FaPen className='text-sm mb-1 text-green hidden sm:block' />
              </button>
            )}
          </div>
        </div>

        <div className='w-full flex items-center gap-x-2 h-[45px] text-white'>
          <button type='button'
            onClick={handleToggleYoutubeStream}
            className="w-1/4 h-full rounded-xl bg-lightGray/80 flex items-center justify-between px-3">
            <div className="flex items-center gap-x-2">
              <YouTubeIcon className="fill-white w-[20px] h-auto aspect-square" />
              <p className="text-white font-wide font-semibold uppercase text-sm lg:text-base hidden md:block">Youtube</p>
            </div>
            <div className={`flex items-center justify-center w-[20px] h-auto aspect-square rounded-lg border border-steelGray text-black
                              ${tournament.streams.activeStream === 'youtube' ? 'bg-green' : ''}`}>
              {tournament.streams.activeStream === 'youtube' ? (
                <FaCheck/>
              ) : ''}
            </div>
          </button>
          <div className={`relative w-1/2 h-full bg-lightGray rounded-xl flex flex-col justify-start px-4 py-1 border-2
                              ${youtubeLinkEdit ? `${youtubeLinkValid ? 'border-green' : 'border-red'}` : 'border-green/0'}`}>
            <p className={`${youtubeLinkEdit ? 'absolute top-0 left-3 -translate-y-1/2 bg-lightGray p-1 px-2' : 'bg-lightGray/0'} font-semibold text-sm rounded transition-all`}>URL</p>
            {youtubeLinkEdit ? (
              <input type='text'
                onChange={(e) => validateYoutubeLink(e.target.value)}
                ref={youtubeLinkInputRef}
                className={`w-full h-full p-2 pl-1 text-white font-compact border-none outline-none bg-transparent opacity-0 ${youtubeLinkEdit ? 'opacity-100' : ''}`} />
            ) : (
              <p className='text-steelGray text-sm font-semibold whitespace-nowrap overflow-x-scroll'>{tournament.streams.youtube}</p>
            )}
          </div>
          <div className='w-1/4 h-full font-semibold'>
            {youtubeLinkEdit ? (
              <div className='w-full h-full flex items-center'>
                <button type='button'
                  disabled={!youtubeLinkValid}
                  onClick={handleSaveYoutubeLink}
                  className='w-3/4 h-full bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green transition-all text-black rounded-l-xl uppercase font-bold'>
                  Save
                </button>
                <button type='button'
                  onClick={() => setYoutubeLinkEdit(false)}
                  className='flex items-center justify-center w-1/4 h-full bg-red/50 hover:bg-red/70 text-white rounded-r-xl uppercase font-bold'>
                  <IoCloseSharp className='text-3xl' />
                </button>
              </div>
            ) : (
              <button type='button'
                disabled={false}
                onClick={handleYoutubeLinkEdit}
                className='flex items-center gap-x-3 w-full h-full justify-center bg-lightGray rounded-xl uppercase  hover:opacity-75 disabled:opacity-50 transition-opacity text-sm sm:text-base'>
                <span>Edit URL</span>
                <FaPen className='text-sm mb-1 text-green hidden sm:block' />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ): '';
}

export default LiveBroadcasts;
