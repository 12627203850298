// components
import NoticeText from "@src/components/ui/NoticeText";

const Transfers = () => {
  return (
    <div className='flex flex-col gap-y-2 mt-2'>
      <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
        <span>Transfer History</span>
      </h2>
      <div className='flex flex-col'>
        <NoticeText>
          Coming Soon
        </NoticeText>
      </div>
    </div>
  );
};

export default Transfers;
