import { Fragment, ReactNode, useEffect, useRef } from "react";
// packages
import { Dialog, DialogPanel, Transition, TransitionChild,  } from "@headlessui/react";
// components
import PopoverText from "./PopoverText";
// icons
import { CrossIcon } from "../common/icons/Header";
import { CreateBackArrowIcon, RightCheckIcon } from "../common/icons/ProfileIcons";

interface IModal {
  scroll?: boolean,
  step?: number,
  totalSteps?: number,
  onBackClick?: () => void,
  title: string,
  children: ReactNode,
  open: boolean,
  setOpen: (open: boolean) => void,
  buttonNegative?: boolean,
  buttonDisabled?: boolean,
  buttonOnClick?: () => void,
  buttonText?: ReactNode,
  disableClickOff?: boolean,
  onContainerLoad?: (container: HTMLDivElement) => void,
  stepNames?: string[],
  changeStep?: (stepNum: number) => void,
}

const Modal: React.FC<IModal> = ({title, children, open, setOpen, buttonNegative, buttonDisabled, buttonOnClick, buttonText, step, totalSteps, onBackClick, scroll, onContainerLoad, disableClickOff, stepNames, changeStep}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current && onContainerLoad) {
      onContainerLoad(containerRef.current);
    }
  }, [containerRef, onContainerLoad]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible'
    };
  }, [open]);

  return (
    <Transition appear show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => {
      if (!disableClickOff) setOpen(false)
    }}>
      <div className="fixed inset-0 overflow-y-auto mt-[4.75rem] lg:mt-[7.5rem]">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end sm:items-center justify-center sm:p-4 text-center w-full after:w-full after:h-full after:absolute after:bg-black/70">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full transform overflow-hidden sm:max-w-[30rem] flex-shrink-0 flex items-center relative z-10">
                <div className={`w-full mx-auto max-h-[30.15rem] overflow-y-auto scrollbar_none !bg-lightBlack rounded-none sm:rounded-xl
                                ${scroll === true ? 'h-[60vh]' : 'h-fit'}`}>
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-center px-4 py-3 border-b-[0.063rem] border-lightGray h-fit">
                      <div className="flex items-center gap-2">
                        {onBackClick ? (
                          <button
                            className="cursor-pointer"
                            onClick={onBackClick}>
                            <CreateBackArrowIcon />
                          </button>
                        ) : ''}
                        <h5 className="text-white font-bold font-compact text-base leading-[1rem] mt-1 uppercase">
                          {title}
                        </h5>
                      </div>
                      <button type='button' aria-label={`close ${title} modal`}
                              onClick={() => setOpen(false)}
                              className="cursor-pointer">
                        <CrossIcon/>
                      </button>
                    </div>

                    <div className='w-full p-4 text-steelGray font-compact h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]' ref={containerRef}>
                      {totalSteps !== undefined  && step !== undefined ? (
                        <div className={`flex ${totalSteps > 6 ? 'gap-[16px]' : 'gap-[22px]'} items-center justify-center pt-6 relative z-[2] mb-[20px]`}>
                          {Array.from({length: step - 1}).map((_item, index) => (
                            <button key={`step-change-button-${index}`}
                                    type="button" aria-label={`Change to step ${index}`}
                                    disabled={!changeStep}
                                    onClick={() => {
                                      if (changeStep) {
                                        changeStep(index);
                                      }
                                    }}
                                    className="w-fit h-fit relative group z-[2]">
                              {stepNames && stepNames[index] ? (
                                <PopoverText visible={true} bottom={true} className="opacity-0 group-hover:opacity-100 transition-opacity">
                                  {stepNames[index]}
                                </PopoverText>
                              ) : ''}
                              <div key={`${index}-progress-completed-circle`} className={`relative bg-green w-[20px] h-[20px] rounded-full flex justify-center items-center
                                              ${index !== 0 ? `after:block after:content-[""] after:absolute after:left-0 after:top-1/2 after:-translate-y-1/2 after:-translate-x-full after:h-[2px] ${totalSteps > 6 ? 'after:w-[16px]' : 'after:w-[22px]'} after:!bg-green` : ''}`}>
                                <RightCheckIcon className="w-[10px] h-auto aspect-square stroke-black"/>
                              </div>
                            </button>
                          ))}
                          <div className="w-fit h-fit relative group z-[2]">
                            {stepNames && stepNames[step - 1] ? (
                                <PopoverText visible={true} bottom={true} className="opacity-0 group-hover:opacity-100 transition-opacity">
                                  {stepNames[step - 1]}
                                </PopoverText>
                              ) : ''}
                            <div className={`relative outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[20px] h-[20px] rounded-full bg-lightBlack
                                            ${step !== 1 ? `after:block after:content-[""] after:absolute after:left-0 after:top-1/2 after:-translate-y-1/2 after:-translate-x-full after:h-[2px] ${totalSteps > 6 ? 'after:w-[16px]' : 'after:w-[22px]'} after:!bg-green` : ''}`}></div>
                          </div>
                          {Array.from({length: totalSteps - step}).map((_item, index) => (
                            <div key={`step-change-button-${step + index}`} className="w-fit h-fit relative group z-[2]">
                              {stepNames && stepNames[step + index] ? (
                                <PopoverText visible={true} bottom={true} className="opacity-0 group-hover:opacity-100 transition-opacity">
                                  {stepNames[step + index]}
                                </PopoverText>
                              ) : ''}
                              <div key={`${index}-progress-circle`} className={`relative outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[20px] h-[20px] rounded-full after:block after:content-[""] after:absolute after:left-0 after:top-1/2 after:-translate-y-1/2 after:-translate-x-full after:h-[2px] ${totalSteps > 6 ? 'after:w-[16px]' : 'after:w-[22px]'} after:!bg-ebonyClay bg-lightBlack`}></div>
                            </div>
                          ))}
                        </div>

                      ) : ''}
                      {children}
                    </div>

                    {buttonText && buttonOnClick ? (
                      <div className="py-3 px-4 border-t-[0.063rem] border-lightGray w-full h-fit flex-shrink-0">
                        <button
                          disabled={buttonDisabled === true}
                          onClick={buttonOnClick}
                          className={`flex justify-center items-center font-compact w-full text-base font-semibold py-[0.938rem] !leading-4 rounded-xl
                                    uppercase text-center
                                    ${buttonNegative ? "bg-red/70 hover:bg-red gap-2 disabled:opacity-50 disabled:hover:bg-red/70 text-white"
                                    : "bg-green hover:bg-gorse gap-2 disabled:opacity-50 disabled:hover:bg-green text-black"}`}>
                          {buttonText}
                        </button>
                      </div>
                    ) : ''}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition>
  );
};

export default Modal;
