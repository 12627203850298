import { ReactElement, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// context
import { useAuthContext } from "@src/provider/AuthContextProvider";
import TournamentProvider from "./TournamentProvider";
import LeaderboardProvider from "./LeaderboardProvider";
import TournamentAdminProvider from "./AdminSettings/TournamentAdminProvider";
// Components:
import Hero from "./Hero/Hero";
import { SkeletonDark } from "../common/skeleton-loader/Skeletons";
import SubViewBar from "./SubViewBar";
// Brands
import SurfsharkDiscountButton from "../brands/surfshark/SurfsharkDiscountButton";
// -- Subviews :
// ---- Overview
import Status from "./Overview/Status";
import Summary from "./Overview/Summary";
import Rules from "./Overview/Rules";
// ----Entrants
import Entrants from "./Entrants/Entrants";
// ---- PrizePool
import PrizePool from "./PrizePool/PrizePool";
// ---- Leaderboards/Results
import Leaderboards from "./Leaderboards/Leaderboards"
// ---- Maps
import Maps from "./Maps/Maps";
// ---- Stats
import Stats from "./Stats/Stats";
// ---- Admin Settings (protected)
import AdminSettings from "./AdminSettings/AdminSettings";
// icons
import { LuUnlink } from "react-icons/lu";
import { Tournament } from "@src/firestore/tournaments";

export enum TournamentSubViews { // options for which bottom view is visible
  overview,
  prizePool,
  entrants,
  leaderboards,
  maps,
  stats,
  adminSettings,
}

const TournamentView = (): ReactElement => {
  const location = useLocation();
  const { userObj } = useAuthContext();

  const [currentSubView, setCurrentSubView] = useState<TournamentSubViews>(TournamentSubViews.overview); // TODO put back to overview
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tournamentLoaded, setTournamentLoaded] = useState<boolean>(false);
  const [tournamentFound, setTournamentFound] = useState<boolean>(false);

  useEffect(() => {
    const pathName = location.pathname;
    const resultsPattern = /\/results$/
    if (resultsPattern.test(pathName)) {
      setCurrentSubView(TournamentSubViews.leaderboards);
    }
  }, [location.pathname])

  const InnerView = () => (
    <LeaderboardProvider>
      {tournamentLoaded ? (
        <>
          {tournamentFound ? (
            <>
              <Hero setCurrentSubView={setCurrentSubView}/>
              <SubViewBar currentSubView={currentSubView} setCurrentSubView={setCurrentSubView} />
              {/* Subviews */}
              <div className="w-full h-fit relative">
                {(tournament && tournament.brand === 'Surfshark') && (currentSubView !== TournamentSubViews.adminSettings && currentSubView !== TournamentSubViews.stats) ? (
                  <div className="w-full flex items-center justify-end px-4 sm:px-8 lg:px-12 absolute top-0 left-0 -translate-y-1/3">
                    <SurfsharkDiscountButton/>
                  </div>
                ) : ''}

                <div className={`${currentSubView === TournamentSubViews.overview ? '' : 'hidden'}`}>
                  <Status />
                  <Summary />
                  <Rules />
                </div>

                <div className={`${currentSubView === TournamentSubViews.prizePool ? '' : 'hidden'}`}>
                  <PrizePool />
                </div>

                <div className={`${currentSubView === TournamentSubViews.entrants ? '' : 'hidden'}`}>
                  <Entrants />
                </div>

                <div className={`${currentSubView === TournamentSubViews.leaderboards ? '' : 'hidden'}`}>
                  <Leaderboards />
                </div>

                <div className={`${currentSubView === TournamentSubViews.maps ? '' : 'hidden'}`}>
                  <Maps />
                </div>

                <div className={`${currentSubView === TournamentSubViews.stats ? '' : 'hidden'}`}>
                  <Stats />
                </div>

                <div className={`${currentSubView === TournamentSubViews.adminSettings ? '' : 'hidden'}`}>
                  <AdminSettings/>
                </div>
              </div>
            </>
          ) : (
            <div className="relative w-full min-h-[80vh]">
              <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                            text-xl sm:text-4xl font-semibold text-green
                            flex flex-col sm:flex-row items-center gap-x-4">
                <p>Tournament not found</p>
                <LuUnlink/>
              </h3>
            </div>
          )}
        </>
      ) : (
        <div className="custom_container pb-10 md:pb-14 mt-[80px]">
          <div className="w-full flex justify-between items-center">
            <SkeletonDark className="w-[75px] h-[40px] rounded-lg"/>
            <SkeletonDark className="w-[100px] h-[20px] rounded-lg"/>
          </div>
          <div className="flex justify-between mt-5">
            <SkeletonDark className="w-[33%] h-[300px] rounded-lg"/>
            <SkeletonDark className="w-[63%] h-[300px] rounded-lg"/>
          </div>
          <div className="flex gap-x-5 mt-[50px]">
            <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
            <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
            <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
            <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
            <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
          </div>
          <div className="mt-7">
            <SkeletonDark className="w-[150px] h-[25px] rounded-lg"/>
            <div className="flex w-full justify-between mt-3">
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
            </div>
          </div>
          <div className="mt-[50px]">
            <SkeletonDark className="w-[150px] h-[25px] rounded-lg"/>
            <div className="flex w-full justify-between mt-3">
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
              <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
            </div>
          </div>
          <div>
            <SkeletonDark className="w-1/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-2/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-4/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-1/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-2/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
            <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
          </div>
        </div>
      )}
    </LeaderboardProvider>
  );

  return (
    <div className="custom_container pb-10 md:pb-14">
      <TournamentProvider announceTournament={setTournament} setTournamentLoaded={setTournamentLoaded} setTournamentFound={setTournamentFound}>
        {userObj && userObj.admin ? (
          <TournamentAdminProvider>
            <InnerView/>
          </TournamentAdminProvider>
        ) : (
          <>
            <InnerView/>
          </>
        )}
      </TournamentProvider>
    </div>
  );
};

export default TournamentView;
