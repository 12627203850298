import { Link } from 'react-router-dom';
// utils
import { logEventToAnalytics } from '@src/utils/Analytics/Analytics';
// assetts
import surfsharkBanner from '@assets/images/brands/surfshark/surfsharkDesktop.png';

const SurfsharkAdDesktop = () => {

  const handleClick = () => {
    logEventToAnalytics('surfshark_advert_click', { source: 'advert_desktop'})
  }

  return (
    <Link to="https://surfshark.com/influencer/vs" target="_blank"
          onClick={handleClick}
          className='rounded-lg !overflow-hidden w-full h-auto aspect-[9.4] hover:opacity-75 transition-opacity'>
      <img src={surfsharkBanner} alt="Surfshark: Enter Coupon VS for extra 4 months"
           className='w-full h-full object-cover rounded-lg'/>
    </Link>
  )
}

export default SurfsharkAdDesktop;
