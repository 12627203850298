import { useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc} from 'firebase/firestore';
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useAuthContext } from '@src/provider/AuthContextProvider';
// types
import { TournamentStatus } from "@src/firestore/tournaments";
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@src/components/ui/Modal';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

const AnnounceTournament = () => {
  const { userObj } = useAuthContext();
  const { tournament } = useTournamentContext();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const announceLimit = 2;

  const announceTournament = async () => {
    setSubmitting(true);
    if (tournament && tournament.status < TournamentStatus.confirmation && userObj && userObj.admin) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentUpdatePromise = updateDoc(tournamentRef, {
        announce: tournament.announce + 1
      })

      toast.promise(tournamentUpdatePromise, {
        pending: 'Announcing Tournament',
        success: 'Tournament announced',
        error: 'Error announcing tournament'
      })
      await tournamentUpdatePromise;
    }
    setSubmitting(false);
    setConfirmOpen(false);
  }

  return tournament ? (
    <div>
      <button type="button"
              disabled={tournament.status > TournamentStatus.registration || tournament.announce >= announceLimit || tournament.hidden}
              onClick={() => setConfirmOpen(true)}
              className="flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                        hover:bg-gorse transition-colors disabled:opacity-50 disabled:hover:bg-green">

        <span>
          {tournament.announce > 0 ? 'Announce Tournament Again' : 'Announce Tournament'}
        </span>
        {submitting ? (
          <ImSpinner8 className='animate-spin'/>
        ) : ''}
      </button>
      {tournament.hidden ? (
        <p className={`text-sm font-compact my-2 text-red`}>
          Cannot announce tournament until it is published
        </p>
      ) : ''}
      {tournament.status > TournamentStatus.registration ? (
        <p className='text-red font-compact text-sm my-2'>
          Cannot announce tournament after registration phase
        </p>
      ) : ''}
      {tournament.announce > 0 ? (
        <p className={`text-sm font-compact my-2 ${tournament.announce === announceLimit ? 'text-red' : 'text-steelGray'}`}>
          Announced {tournament.announce} times (Limit: {announceLimit})
        </p>
      ) : ''}
      <Modal title='Announce Tournament'
             open={confirmOpen}
             setOpen={setConfirmOpen}
             buttonDisabled={tournament.status > TournamentStatus.registration || tournament.announce >= announceLimit || tournament.hidden}
             buttonText={(
              <div className='flex items-center gap-x-2'>
                <span>
                  Confirm
                </span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
             )}
             buttonOnClick={announceTournament}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
              <em className="not-italic text-red/70 uppercase"> WARNING! </em>
              Sending announcment notifications cannot be undone.
            </p>
            <p>
              By confirming you acknowledge that all tournament informations are correctly set.
            </p>
            <p>
              Are you sure?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  ) : '';
}

export default AnnounceTournament;
