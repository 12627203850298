import React, { MouseEvent, useCallback, useEffect, useState, useRef, ChangeEvent, MutableRefObject } from 'react'
import { InputCross } from '../common/icons/Common';
import { FaLock } from 'react-icons/fa';

interface IInput {
  value: string,
  onChange: (newValue: string) => void,
  label?: string,
  placeholder?: string,
  autoComplete?: boolean,
  type?: string,
  wrapperClassName?: string,
  labelClassName?: string,
  inputClassName?: string,
  active?: boolean,
  onClick?: (e?: MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  valid?: boolean,
  required?: boolean,
  black?: boolean
  border?: boolean,
  locked?: boolean,
  textArea?: boolean,
  rows?: number
}

const Input: React.FC<IInput> =
({active,
  wrapperClassName,
  labelClassName,
  inputClassName,
  value,
  label,
  placeholder,
  autoComplete,
  onChange,
  onClick,
  valid,
  required,
  type,
  black,
  border,
  locked,
  textArea,
  rows}) => {

    const id = `id-${Math.random().toString(16).slice(2)}`;

    const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

    const [localActive, setLocalActive] = useState<boolean>(false);

    const [cursorPos, setCursorPos] = useState<number | null>(null);

    useEffect(() => {
      const input = inputRef.current;
      if (input) input.setSelectionRange(cursorPos, cursorPos);
   }, [inputRef, cursorPos, value]);

   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) => {
    if (e) {
      setCursorPos(e.target.selectionStart);
      onChange(e.target.value);
    } else {
      onChange('');
    }
  };

    const checkForOutsideInputClick: EventListener = useCallback((e: Event) => {
      if (e.target) {
        const target = (e.target as HTMLElement);
        if (!target.classList.contains(`.${id}-class`) && !target.closest(`.${id}-class`)) {
          setLocalActive(false);
        }
      }
    }, [id]);

    useEffect(() => {
      document.addEventListener('click', checkForOutsideInputClick);

      return () => document.removeEventListener('click', checkForOutsideInputClick);
    }, [checkForOutsideInputClick])

  return (
    <div className={`text-start ${wrapperClassName} w-full ${locked ? 'opacity-50' : ''}`}>
      {label ? (
        <label htmlFor={`${id}`} className={`${labelClassName} text-sm font-compact text-steelGray`}>
          {label}
        </label>
      ) : ''}
      <div className={`${id}-class ${inputClassName} relative mt-2 w-full h-fit`}>
        {textArea ? (
          <textarea ref={inputRef as MutableRefObject<HTMLTextAreaElement | null>}
                    className={`w-full h-full text-sm font-compact font-normal outline-none border-2 ${border ? 'border-lightGray' : 'border-transparent'} text-white placeholder:text-steelGray rounded-xl p-3
                              ${black ? 'bg-black' : 'bg-lightBlack'}
                              ${active ? valid !== false ? "pr-10  !border-green" : "!border-red"
                                       : valid !== false ? "focus:!border-green" : "!border-red/70 focus:!border-red"} transition-all
                              ${locked ? 'pointer-events-none touch-none' : ''}
                              resize-y`}
          onClick={(e) => {
            setLocalActive(true);
            onClick ? onClick(e) : '';
          }}
          value={value}
          rows={rows ? rows : 4}
          onChange={(e) => handleChange(e)}
          required={required}
          placeholder={placeholder}
          autoComplete={autoComplete !== false ? 'true' : 'false'}
          id={id}>

          </textarea>
        ) : (
          <input ref={inputRef as MutableRefObject<HTMLInputElement | null>}
                 className={`w-full h-full text-sm font-compact font-normal outline-none border-2 ${border ? 'border-lightGray' : 'border-transparent'} text-white placeholder:text-steelGray rounded-xl p-3
                            ${black ? 'bg-black' : 'bg-lightBlack'}
                            ${active ? valid !== false ? "pr-10  !border-green" : "!border-red"
                                      : valid !== false ? "focus:!border-green" : "!border-red/70 focus:!border-red"} transition-all
                            ${locked ? 'pointer-events-none touch-none' : ''}`}
          onClick={(e) => {
            setLocalActive(true);
            onClick ? onClick(e) : '';
          }}
          value={value}
          onChange={(e) => handleChange(e)}
          required={required}
          type={type ? type : "text"}
          placeholder={placeholder}
          autoComplete={autoComplete !== false ? 'true' : 'false'}
          id={id}
          />
        )}
        {locked ? (
          <FaLock className="absolute top-1/2 -translate-y-1/2 end-4"/>
        ) : (!textArea && value.length > 0 && (active || localActive)) ? (
          <span onClick={() => {
                  handleChange(null);
                  setLocalActive(false);
                }}
                className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer">
            <InputCross />
          </span>
        ) : ''}
      </div>
    </div>
  )
}

export default Input;
