import { useEffect, useState, useCallback } from "react";
// context
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
// types
import { TeamEditModalStep } from "../../../types";
// components
import Modal from "@src/components/ui/Modal";
import UploadLogo from "./UploadSponsorLogoBox";
// icons
import { FaCheck } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";

const SponsorsStep = () => {
  const { currentEditModalStep, setCurrentEditModalStep, teamInfo, updateTeam, closeModal } = useTeamInfoContext();

  const [formFilled, setFormFilled] = useState(false);
  const [paypalValid, setPaypalValid] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const validatePaypal = useCallback(() => {
    const paypalRegex = /^(((https|http):\/\/)?(www\.)?paypal\.me\/[a-zA-Z0-9]{1,20}|\w+@\w+\.\w+)$/i
    setPaypalValid(teamInfo.paypalLink === '' || paypalRegex.test(teamInfo.paypalLink));
  }, [teamInfo]);

  useEffect(() => {
    validatePaypal();
  }, [teamInfo, validatePaypal])

  const handleStepSubmit = () => {
    handleTeamUpdate();
  };

  const validateForm = useCallback(async () => {
    const fieldsValid = teamInfo.paypalLink !== '' && paypalValid;
    setFormFilled(fieldsValid);
  }, [teamInfo, paypalValid]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleTeamUpdate = async () => {
    setSubmitting(true); // state to track whether form currently submitting
    const teamUpdated: boolean = await updateTeam();
    if (teamUpdated) {
      closeModal();
    }
    setSubmitting(false);
  }

  return (
    <Modal title='Edit Sponsors'
          scroll={true}
          buttonText={(
            <div className="flex items-center gap-x-2">
              <p>Confirm</p>
              {submitting ? (
                <ImSpinner8 className="-translate-y-[1px] animate-spin"/>
              ) : (
                <FaCheck className="-translate-y-[1px]"/>
              )}
            </div>
          )}
          buttonDisabled={submitting || !formFilled}
          buttonOnClick={handleStepSubmit}
          open={currentEditModalStep === TeamEditModalStep.sponsors}
          setOpen={(open: boolean) => {
            setCurrentEditModalStep(open ? TeamEditModalStep.sponsors : -1);
          }}>
      <div className="w-full p-6 ">
        <h2 className="text-steelGray !text-center pb-4 font-compact flex justify-start items-center gap-x-2">
          <span>Sponsors Logos</span>
          <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
        </h2>
        <UploadLogo/>
      </div>
    </Modal>
  );
};

export default SponsorsStep;
