import { useState, useEffect, useCallback, createContext, useContext, ReactNode } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
// context
import { useDateTimeContext } from '@src/provider/DateTimeProvider';
// types
import { AnnouncementConverted, AnnouncementRaw } from '@src/components/adminDashboard/announcements/Announcements';
// utils
import { decodeBase64 } from '@utils/encodingDecoding';
import { useAuthContext } from './AuthContextProvider';

interface IAnnouncementContext {
  announcement: string,
  announcementDismissable: boolean,
  announcementVisible: boolean,
  dismissAnnouncement: () => void,
}

const defaultAnnouncementContext = {
  announcement: '',
  announcementDismissable: false,
  announcementVisible: false,
  dismissAnnouncement: () => false
}

const AnnouncementContext = createContext<IAnnouncementContext>(defaultAnnouncementContext);

export const useAnnouncementContext = () => {
  return useContext(AnnouncementContext);
}

interface IAnnouncementProvider {
  children: ReactNode,
  announceAnnouncementVisible: (visible: boolean) => void
}

const AnnouncementProvider: React.FC<IAnnouncementProvider> = ({children, announceAnnouncementVisible}) => {
  const { userObj } = useAuthContext();
  const { currentTime } = useDateTimeContext();

  const [announcement, setAnnouncement] = useState<string>('');
  const [announcementVisible, setAnnouncementVisible] = useState<boolean>(false);

  const [announcementActive, setAnnouncementActive] = useState<boolean>(false);
  const [announcementTimeout, setAnnouncementTimeout] = useState<Date | null>(null);
  const [announcementDismissable, setAnnouncementDismissable] = useState<boolean>(false);

  useEffect(() => {
    if (announcementActive && (!announcementTimeout || announcementTimeout.getTime() > currentTime)) {
      setAnnouncementVisible(true);
    } else {
      setAnnouncementVisible(false);
    }
  }, [announcementActive, announcementTimeout, currentTime]);

  const dismissAnnouncement = () => {
    setAnnouncementActive(false);
  }

  useEffect(() => {
    announceAnnouncementVisible(announcementVisible);
  }, [announcementVisible, announceAnnouncementVisible]);

  const getAnnouncement = useCallback(() => {
    const announcementDocRef = doc(firestore, 'globals', 'announcement');
    const hostUrl = import.meta.env.VITE_HOST_URL;

    const unsubscribe = onSnapshot(announcementDocRef, (snapshot) => {
      const announcementDoc = snapshot.data() as AnnouncementRaw | undefined;

      if (announcementDoc) {
        const announcementConverted: AnnouncementConverted = {
          ...announcementDoc,
          timeout: announcementDoc.timeout ? new Date(announcementDoc.timeout.seconds * 1000) : null,
        }

        const decodedAnnouncement = decodeBase64(announcementConverted.data);

        if (announcementConverted.active) {
          setAnnouncement(decodedAnnouncement);
          setAnnouncementActive(announcementConverted.active);
          setAnnouncementTimeout(announcementConverted.timeout);
          setAnnouncementDismissable(announcementConverted.dismissable);
        } else {
          if (userObj && !userObj.discord) {
            setAnnouncement(`[Link your Discord](${hostUrl}/settings/notifications) and [Join our server](external-https://discord.gg/versus-gg) to recieve notifications!`);
            setAnnouncementTimeout(null);
            setAnnouncementDismissable(true);
            setAnnouncementActive(true);
          } else {
            setAnnouncement(decodedAnnouncement);
            setAnnouncementActive(false);
            setAnnouncementDismissable(false);
            setAnnouncementTimeout(null);
          }
        }
      }
    })

    return unsubscribe;
  }, [userObj]);

  useEffect(() => {
    const unsubscribe = getAnnouncement();

    return () => unsubscribe();
  }, [getAnnouncement]);

  const contextValue = {
    announcement,
    announcementDismissable,
    announcementVisible,
    dismissAnnouncement
  }

  return (
    <AnnouncementContext.Provider value={contextValue}>
      {children}
    </AnnouncementContext.Provider>
  )
}

export default AnnouncementProvider
