import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// context
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
// types
import { TeamEditModalStep } from "../../../types";
import { Region, regionsAsDropdownOptions, regionToString } from "@src/types/Regions";
import { Game, gamesAsDropDownOptions, gameToString } from "@src/types/Games/Games";
// components
import Modal from "@src/components/ui/Modal";
import DropDown from "@src/components/ui/DropDown";
// icons
import { FaArrowRight } from "react-icons/fa";

const Step2 = () => {
  const [formFilled, setFormFilled] = useState(false);

  const {changeModalStep, currentEditModalStep, setCurrentEditModalStep, teamInfo, setTeamInfo} = useTeamInfoContext();
  const [teamBioValid, setTeamBioValid] = useState<boolean>(false);

  const validateFields = useCallback(() => {
    setTeamBioValid(teamInfo.teamBio === '' || /^.{2,200}$/.test(teamInfo.teamBio));
  }, [teamInfo])

  useEffect(() => {
    validateFields();
  }, [teamInfo, validateFields]);

  const validateForm = useCallback(async () => {
    const fieldsValid = teamBioValid;
    setFormFilled(fieldsValid);
  }, [teamBioValid]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleStepSubmit = () => {
    changeModalStep(1);
  };

  return (
    <Modal title='Edit Team'
          step={2}
          totalSteps={3}
          stepNames={['General', 'Details', 'Payment']}
          scroll={true}
          onBackClick={() => changeModalStep(-1)}
          buttonText={(
            <div className="flex items-center gap-x-2">
              <p>Next</p>
              <FaArrowRight className="-translate-y-[1px]"/>
            </div>
          )}
          buttonDisabled={!formFilled}
          buttonOnClick={handleStepSubmit}
          open={currentEditModalStep === TeamEditModalStep.region}
          setOpen={(open: boolean) => {
            setCurrentEditModalStep(open ? TeamEditModalStep.region : -1);
          }}>
      <div className="px-6">
        <DropDown label="Team Region *"
                  locked={true}
                  currentOption={regionsAsDropdownOptions.find((option) => option.label === teamInfo.region) ?? regionsAsDropdownOptions[0]}
                  options={regionsAsDropdownOptions}
                  handleOnChange={(newValue: Region) => {
                    const regionName = regionToString(newValue);
                    setTeamInfo({...teamInfo, region: regionName});
                  }}
                  black={true}/>
        <p className="text-sm text-red/70 font-compact mt-2 font-medium">
          <span>Changing team regions requires approval </span>
          <Link to={"https://discord.com/channels/609715857429364737/903402173478604891/903402498331648010"} target="_blank"
                className="text-green underline hover:text-gorse transition-colors">
            here
          </Link>
        </p>

        {/* <Input label="Team Bio"
               value={teamInfo.teamBio}
               onChange={(newValue) => setTeamInfo({...teamInfo, teamBio: newValue})}
               textArea={true}
               rows={4}
               black={true}/>
        <p className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                ${teamBioValid ? 'text-green' : 'text-red'} opacity-60`}>
          {teamInfo.teamBio.length}/{teamBioMaxLength}
        </p> */}
      </div>

      <div className="px-6 pb-[100px]">
        <DropDown label="Main Game *"
                  currentOption={gamesAsDropDownOptions.find((option) => option.label === teamInfo.mainGame) ?? gamesAsDropDownOptions[0]}
                  options={gamesAsDropDownOptions}
                  handleOnChange={(newValue: Game) => {
                    const gameName = gameToString(newValue);
                    setTeamInfo({...teamInfo, mainGame: gameName});
                  }}
                  black={true}/>
      </div>
    </Modal>
  );
};

export default Step2;
