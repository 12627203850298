import { useState } from "react";
// types
import { TeamSubView } from "../../types";
// components
import SubViewBar from "./SubViewBar";
// Sub Views
import Performance from "./views/Performance";
import Members from "./views/Members";
import Transfers from "./views/Transfers";
import Stats from "./views/Stats";
import Sponsors from "./views/Sponsors";
import Manage from "./views/manageTeam/ManageTeam";
import { useTeamContext } from "../../TeamShowProvider";


const SubViews = () => {
  const { editor } = useTeamContext();
  const [currentSubView, setCurrentSubView] = useState<TeamSubView>(TeamSubView.performance);

  return (
    <div className="px-4 sm:px-0 flex flex-col gap-y-6">
    <SubViewBar currentSubView={currentSubView} setCurrentSubView={setCurrentSubView}/>
      <div className={`${currentSubView === TeamSubView.performance ? '' : 'hidden'}`}>
        <Performance/>
      </div>
      <div className={`${currentSubView === TeamSubView.members ? '' : 'hidden'}`}>
        <Members/>
      </div>
      <div className={`${currentSubView === TeamSubView.transfers ? '' : 'hidden'}`}>
        <Transfers/>
      </div>
      <div className={`${currentSubView === TeamSubView.stats ? '' : 'hidden'}`}>
        <Stats/>
      </div>
      <div className={`${currentSubView === TeamSubView.sponsors ? '' : 'hidden'}`}>
        <Sponsors/>
      </div>

      <div className={`${editor && currentSubView === TeamSubView.manage ? '' : 'hidden'}`}>
        <Manage/>
      </div>
    </div>
  )
}

export default SubViews;
