import { useState, ReactNode, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
// context
import { useTeamContext } from "../../../TeamShowProvider";
// types
import { PerformanceHistory, PerformanceHistoryStatus, performanceHistoryStatusToString } from "@src/firestore/performanceHistory";
// utils
import { formatDate } from "@src/utils/Date";
import { positionNumberToString } from "@src/firestore/tournamentPointsSystems";
// icons
import { FaClock } from "react-icons/fa";
import { PrizePoolTrophyIcon } from '@src/components/common/icons/TournamentIcons';
import { ApexLegendsIcon } from '@src/components/common/icons/Games';
import { TargetIcon } from '@src/components/common/icons/Common';
import { VscDebugDisconnect } from 'react-icons/vsc';

interface IPerformanceHistoryCard {
  performanceHistory: PerformanceHistory
}

const PerformanceHistoryCard: React.FC<IPerformanceHistoryCard> = ({performanceHistory}) => {
  let placementColor;
  switch (performanceHistory.placement) {
    case 1:
      placementColor = '#D9A746';
      break;
    case 2:
      placementColor = '#6E7681';
      break;
    case 3:
      placementColor = '#C24D28';
      break;
    default:
      placementColor = '#D4FF27';
      break;
  }

  const PlacementIcon = (): ReactNode => {
    const prizeClassName = `w-[30px] h-auto aspect-square`;
    return performanceHistory.placement && performanceHistory.prized ? (
      <PrizePoolTrophyIcon className={prizeClassName} fill={placementColor}/>
    ) : (
      <ApexLegendsIcon className="w-[30px] h-auto aspect-square fill-steelGray"/>
    );
  }

  return (
    <div key={`performance-history-${performanceHistory.id}`}
        className="relative bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2 flex justify-between items-center">
      {(performanceHistory.status !== PerformanceHistoryStatus.registered
        && performanceHistory.status !== PerformanceHistoryStatus.waiting
        && performanceHistory.status !== PerformanceHistoryStatus.confirmed
        && performanceHistory.status !== PerformanceHistoryStatus.ongoing) ? (
        <div className={`absolute left-0  top-1/2 -translate-y-1/2 w-[4px] h-[40px] rounded-r`}
             style={{backgroundColor: performanceHistory.prized ? placementColor : '#4D545E'}}></div>
      ) : ''}
      <div className="flex items-center gap-x-8">
        <div className="flex items-center gap-x-4 min-w-[110px]">
          {(performanceHistory.status === PerformanceHistoryStatus.registered
            || performanceHistory.status === PerformanceHistoryStatus.waiting
            || performanceHistory.status === PerformanceHistoryStatus.confirmed
            || performanceHistory.status === PerformanceHistoryStatus.ongoing) ? (
              <>
                <FaClock className="w-[24px] lg:w-[26px] h-auto aspect-square fill-white"/>
                <p className="font-wide text-2xl font-semibold uppercase">TBC</p>
              </>
            ) : performanceHistory.status === PerformanceHistoryStatus.eliminated ? (
              <>
                <TargetIcon className='w-[24px] lg:w-[26px] h-auto aspect-square fill-steelGray'/>
                <p className={`text-steelGray font-wide text-2xl font-semibold uppercase`}>ELI</p>
              </>
            ) : (
              <>
                <PlacementIcon/>
                <p className={`${performanceHistory.prized ? `text-[${placementColor}]` : 'text-steelGray'} font-wide text-2xl font-semibold uppercase`}>{positionNumberToString(performanceHistory.placement)}</p>
              </>
            )
          }
        </div>
        <Link to={`/tournaments/${performanceHistory.tournamentId}`}
              className="group flex flex-col hover:opacity-75 transition-opacity">
          <p className="font-compact text-white group-hover:text-green transition-colors uppercase font-semibold lg:text-[1.1rem]">{performanceHistory.tournamentName}</p>
          <p className="text-steelGray text-sm uppercase font-compact font-semibold -my-1">{formatDate(performanceHistory.tournamentStartDate)}</p>
        </Link>
      </div>

      <div className="flex items-center gap-x-6">
        {performanceHistory.participatingPlayerData.length > 0 ? (
          <div className='hidden sm:flex items-center w-fit justify-end'>
            {performanceHistory.participatingPlayerData.map((player, index) => {
              return (
                <Link to={`/profile/${player.id}`} key={`${player.id}-image-stack`}
                      className="group relative w-[30px] h-auto aspect-square rounded-full bg-lightBlack border-2 border-lightBlack overflow-hidden -ml-[9px]"
                      style={{zIndex: index + 1}}>
                    <img src={player.displayImage} alt={`${player.displayName} image`}
                        className={`group-hover:opacity-75 transition-opacity w-full h-full object-cover}`}/>
                </Link>
              );
            })}
          </div>
        ) : ''}
        <div className="bg-lightGray rounded-xl w-[100px] sm:w-[150px] h-[32px] flex items-center justify-center">
          {performanceHistory.status === PerformanceHistoryStatus.placement ? (
            <>
              {performanceHistory.winnings !== null && performanceHistory.winnings > 0 ? (
                <p className={`${performanceHistory.prized ? `text-[${placementColor}]` : 'text-steelGray'} font-compact text-xl lg:text-2xl font-semibold`}>
                  ${performanceHistory.winnings}
                </p>
              ) : (
                <p className="text-steelGray font-compact text-3xl font-semibold">-</p>
              )}
            </>
          ) : (
            <p className="text-white font-compact uppercase">{performanceHistoryStatusToString(performanceHistory.status)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Performance = () => {
  const { teamPerformanceHistories} = useTeamContext();
  const filteredPerformanceHistories = useMemo(() => teamPerformanceHistories
  .filter((performanceHistory) => (performanceHistory.status !== PerformanceHistoryStatus.declined
                                   && performanceHistory.status !== PerformanceHistoryStatus.ignored)), [teamPerformanceHistories]);

  const [performanceHistoriesByYear, setPerformanceHistoriesByYear] = useState<[string, PerformanceHistory[]][]>([]);

  useEffect(() => {
    const localPerformanceHistoriesByYearObject: Record<string, PerformanceHistory[]> = {};

    for (const performanceHistory of filteredPerformanceHistories) {
      const year = performanceHistory.tournamentStartDate.getFullYear().toString();

      if (localPerformanceHistoriesByYearObject[year]) {
        localPerformanceHistoriesByYearObject[year].push(performanceHistory);
      } else {
        localPerformanceHistoriesByYearObject[year] = [performanceHistory];
      }
    }

    const localPerformanceHistoriesByYearEntries: [string, PerformanceHistory[]][] =
    Object.entries(localPerformanceHistoriesByYearObject).sort((a, b) => parseInt(b[0]) - parseInt(a[0]));

    setPerformanceHistoriesByYear(localPerformanceHistoriesByYearEntries);

  }, [filteredPerformanceHistories]);

  return (
    <div className='flex flex-col gap-y-2 mt-2'>
      <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
        <span>Performance History</span>
      </h2>
      <div className='flex flex-col'>
        {performanceHistoriesByYear.length > 0 ? (
          <>
           {performanceHistoriesByYear.map(([year, histories], index) => {
              return (
                <>
                  {histories.map((performanceHistory) => {
                    return (
                      <PerformanceHistoryCard key={`performance-history-card-${performanceHistory.id}`}
                                              performanceHistory={performanceHistory}/>
                    );
                  })}

                  <div key={`performance-history-year-${year}`}
                       className={`relative w-full h-[1px] bg-lightGray mt-5 sm:mt-4 mb-2 sm:mb-3 ${index === performanceHistoriesByYear.length - 1 ? 'hidden' : ''}`}>
                    <p className='bg-black px-2 font-compact font-medium text-steelGray w-fit leading-4
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                      {year}
                    </p>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <div className='flex flex-col items-center mt-10 gap-y-2'>
            <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
              <VscDebugDisconnect className='text-white text-2xl'/>
            </div>
            <p className='font-wide text-white text-2xl font-semibold uppercase'>No Data</p>
            <p className='text-steelGray font-compact'>Performance histories will show here after team participates in a tournament</p>
          </div>
        )}
      </div>
    </div>
  )
};

export default Performance;
