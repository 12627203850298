import { useState, ReactNode, useEffect } from "react";
// context
import { useTournamentContext } from "@src/components/tournament/TournamentProvider";
import { useTournamentAdminContext } from "../../../TournamentAdminProvider";
// types
import { Prize } from "@src/firestore/tournaments";
// utils
import { positionNumberToString } from "@src/firestore/tournamentPointsSystems";
// components
import ButtonPrimary from "@src/components/ui/ButtonPrimary";
import PopoverText from "@src/components/ui/PopoverText";
import NoticeText from "@src/components/ui/NoticeText";
// libaries
import { toast } from "react-toastify";
// icons
import { MedalIcon, TrophyIcon } from "@src/components/common/icons/Common";
import { FaCheck, FaLock, FaPen, FaPlus } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { ImSpinner8 } from "react-icons/im";

interface ITournamentEditPage {
  checkInStarted: boolean
}

const TeamPrizes: React.FC<ITournamentEditPage> = ({checkInStarted}) => {
  const { tournament } = useTournamentContext();
  const { tournamentEditInfo, setTournamentEditInfo, saveTournamentEditInfo } = useTournamentAdminContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [stepValid, setStepValid] = useState<boolean>(false);

  const [editingPrize, setEditingPrize] = useState<number>(-1);

  const prizeCurrencyString = tournamentEditInfo?.prizePool.currency ?? '';
  const teamPrizes = tournamentEditInfo?.prizePool.prizes.filter((prize) => prize.position) ?? [];


  const getPrizeBgColorForPosition = (position: number) => {
    switch (position) {
      case 1:
        return '!bg-yellow'
      case 2:
        return '!bg-slateGray'
      case 3:
        return '!bg-lightRed'
      default:
        return '!bg-green'
    }
  }

  const getPrizeIconForPosition = (position: number): ReactNode => {
    switch (position) {
      case 1:
        return <TrophyIcon className='w-[25px] h-auto aspect-square object-contain fill-yellow'/>;
      case 2:
        return <TrophyIcon className='w-[25px] h-auto aspect-square object-contain fill-slateGray'/>;
      case 3:
        return <TrophyIcon className='w-[25px] h-auto aspect-square object-contain fill-lightRed'/>;
      default:
        return <MedalIcon className='w-[25px] h-auto aspect-square object-contain fill-green'/>;
    }
  }

  const addPrize = () => {
    if (!tournamentEditInfo) return;

    const localTeamPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => prize.position);

    if (localTeamPrizes.length === 20) {
      toast.error('Cannot add more than 20 placement prizes');
      return;
    }

    const otherPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => !prize.position);

    const nextPrizePosition = localTeamPrizes.length > 0 ? localTeamPrizes[localTeamPrizes.length - 1].position! + 1 : 1;

    const newPrize: Prize = {
      position: nextPrizePosition,
      prizeName: positionNumberToString(nextPrizePosition),
      special: false,
      prizeAmount: 0,
      prizeDescription: 'placement'
    }

    localTeamPrizes.push(newPrize);

    setTournamentEditInfo({
      ...tournamentEditInfo,
      prizePool: {
        ...tournamentEditInfo.prizePool,
        prizes: [...localTeamPrizes, ...otherPrizes]
      }
    })
  };

  const updatePrize = (prizeIndex: number, newValue: string) => {
    if (!tournamentEditInfo) return;

    const localTeamPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => prize.position);
    const otherPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => !prize.position);

    const valueConverted = parseInt(newValue, 10);
    const valueNumbered = isNaN(valueConverted) ? 0 : valueConverted;
    const valueStripped = valueNumbered === 0 ? 0 : parseInt(valueNumbered.toString().replace(/^0+/, ''), 10);

    localTeamPrizes[prizeIndex].prizeAmount = valueStripped;

    setTournamentEditInfo({
      ...tournamentEditInfo,
      prizePool: {
        ...tournamentEditInfo.prizePool,
        prizes: [...localTeamPrizes, ...otherPrizes]
      }
    })
  };

  const removeLastPrize = () => {
    if (!tournamentEditInfo) return;
    const localTeamPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => prize.position);
    const otherPrizes = tournamentEditInfo.prizePool.prizes.filter((prize) => !prize.position);

    localTeamPrizes.pop();
    setTournamentEditInfo({
      ...tournamentEditInfo,
      prizePool: {
        ...tournamentEditInfo.prizePool,
        prizes: [...localTeamPrizes, ...otherPrizes]
      }
    })
  }

  const handleSaveChanges = async () => {
    setSubmitting(true);
    await saveTournamentEditInfo();
    setSubmitting(false);
  }

  useEffect(() => {
    if (tournament && tournamentEditInfo) {
      if (tournament.prizePool.currency !== tournamentEditInfo.prizePool.currency
          || tournament.prizePool.prizes !== tournamentEditInfo.prizePool.prizes) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    } else {
      setChangesMade(false);
    }
  }, [tournament, tournamentEditInfo]);

  useEffect(() => {
    if (tournamentEditInfo) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentEditInfo]);

  return tournament && tournamentEditInfo ? (
    <div className='text-white font-compact flex flex-col gap-y-2'>
      <div className='w-full flex flex-col gap-y-2'>
        {teamPrizes.length > 0 ? (
          <>
            {teamPrizes.map((prize, index) => {
              const prizeIcon = getPrizeIconForPosition(prize.position!);
              return (
                <div key={`team-position-prize-${prize.position}`} className='relative flex items-center gap-x-2 h-[45px] text-white'>
                  <div className={`relative w-1/2 h-full bg-lightBlack rounded-xl flex items-center gap-x-4 pl-4 py-2`}>
                    <div className={`absolute left-0 top-1/2 -translate-y-1/2 w-[2px] h-[60%] ${getPrizeBgColorForPosition(prize.position!)}`}></div>
                    <div>
                      {prizeIcon}
                    </div>
                    <p className="font-wide font-semibold uppercase">
                      {positionNumberToString(prize.position!)} place
                    </p>
                  </div>
                  <div className={`w-1/4 h-full ${editingPrize === index ? 'bg-ebonyClay' : 'bg-lightGray'} rounded-xl flex items-center px-4 justify-start font-wide font-semibold`}>
                    {editingPrize === index ? (
                      <input value={prize.prizeAmount} onChange={(e) => updatePrize(index, e.target.value)}
                             className="w-full h-full bg-transparent text-white font-wide font-semibold uppercase flex items-center justify-start outline-none border-none"/>
                    ) : (
                      <p>{prizeCurrencyString}{prize.prizeAmount} </p>
                    )}
                  </div>
                  {editingPrize === index ? (
                    <button type='button'
                            onClick={() => setEditingPrize(-1)}
                            className='w-1/4 h-full bg-lightGray rounded-xl uppercase  hover:opacity-75 transition-opacity disabled:opacity-50
                                      text-sm sm:text-base flex items-center font-compact font-medium gap-x-2 justify-center'>
                      <span>Confirm</span>
                      <FaCheck className="w-[12px] fill-green"/>
                    </button>
                  ) : (
                    <button type='button'
                            onClick={() => setEditingPrize(index)}
                            className='w-1/4 h-full bg-lightGray rounded-xl uppercase  hover:opacity-75 transition-opacity disabled:opacity-50
                                      text-sm sm:text-base flex items-center font-compact font-medium gap-x-2 justify-center'>
                      <span>Edit Prize</span>
                      <FaPen className="w-[12px] fill-green"/>
                    </button>
                  )}
                  {index === teamPrizes.length - 1 ? (
                    <button onClick={removeLastPrize}
                            className="absolute top-1/2 right-0 translate-x-[calc(100%+8px)] -translate-y-1/2
                                      w-auto h-full aspect-square bg-lightGray rounded-xl flex items-center justify-center
                                      hover:opacity-75 transition-opacity">
                      <FaXmark className="text-red w-[18px] h-auto"/>
                    </button>
                  ) : ''}
                </div>
              );
            })}
          </>
        ) : (
          <NoticeText className="h-[45px] items-center justify-center flex my-0">
            No Team Prizes
          </NoticeText>
        )}
      </div>

      <button type="button" aria-label="Add Prize"
              disabled={teamPrizes.length >= 20}
              onClick={addPrize}
              className="w-full h-[45px] flex items-center font-compact font-medium gap-x-2 justify-center
                         bg-lightGray rounded-xl uppercase  hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-opacity">
        <span>
          Add Prize
        </span>
        <FaPlus className="-translate-y-[1px] text-green w-[16px] h-auto"/>
      </button>

      <div className='relative group w-full h-fit mt-4'>
        {checkInStarted ? (
          <PopoverText visible={true} right={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
            Unable to affect changes after check-in has started
          </PopoverText>
        ) : ''}
        <ButtonPrimary disabled={checkInStarted || submitting || !changesMade || !stepValid}
                      onClick={handleSaveChanges}>
          <span>Save Changes</span>
          {checkInStarted ? (
            <FaLock/>
          )
          : submitting ? (
            <ImSpinner8/>
          ) : ''}
        </ButtonPrimary>
      </div>
    </div>
  ) : '';
}

export default TeamPrizes;
