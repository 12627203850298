import { initializeApp } from "firebase/app";

import {
  getAuth,
  connectAuthEmulator,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { getFirestore,
  connectFirestoreEmulator
} from "firebase/firestore";
import { getFunctions,
  connectFunctionsEmulator
} from "firebase/functions";
import { getStorage,
  connectStorageEmulator
} from "firebase/storage";
import { getAnalytics, Analytics  } from "firebase/analytics";

import appConfig from "./config";

export const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

const firebase = initializeApp(config);

const firestore = getFirestore(firebase);

const auth = getAuth();

let analytics: Analytics | null = null;

if (appConfig.ENV !== "dev") {
  analytics = getAnalytics(firebase);
}

export function logout() {
  signOut(auth)
    .then(() => {
      window.location.replace("/login");
    })
    .catch((error) => {
      console.log(error);
    });
}

const gProvider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
  signInWithPopup(auth, gProvider);
};

const functions = getFunctions(firebase);

const storage = getStorage();

if (appConfig.ENV === "dev") {
  connectFirestoreEmulator(firestore, "localhost", 8081);

  connectAuthEmulator(auth, "http://localhost:9099");

  connectFunctionsEmulator(functions, "localhost", 5002);

  connectStorageEmulator(storage, "localhost", 9199);
}

export default firebase;
export { firestore, storage, functions, auth, analytics };
