import { useState, useEffect, useRef, ReactNode, forwardRef } from 'react';
// components
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
// icons
import { DownSelectArrow } from '@src/components/common/icons/Common';
import { FaLock } from 'react-icons/fa';

export interface DropDownOption {
  value: number,
  label: string,
}

interface IDropDown {
  currentOption: DropDownOption,
  options: DropDownOption[],
  label?: string,
  className?: string,
  wrapperClassName?: string,
  labelClassName?: string,
  icon?: ReactNode
  loaded?: boolean,
  locked?: boolean,
  black?: boolean,
  containerToScroll?: HTMLDivElement | null,
  handleOnChange: (option: number) => void,
}

const DropDown= forwardRef<HTMLDivElement, IDropDown>(({currentOption, options, icon, label, handleOnChange, loaded, className, wrapperClassName, black, containerToScroll, locked, labelClassName}, ref) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const handleCloseDropDownOnOutsideClick = (e: MouseEvent) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => handleCloseDropDownOnOutsideClick(e);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropDownOpen && containerToScroll) {
      setTimeout(() => {
        containerToScroll.scrollTop = containerToScroll.scrollHeight;
      }, 30);
    }
  }, [dropDownOpen, containerToScroll]);

  return loaded !== false ? (
    <div className={`text-left ${wrapperClassName} w-full ${locked ? 'opacity-50' : ''}`}>
      {label ? (
        <p className={`${labelClassName} text-sm font-compact text-steelGray`}>
          {label}
        </p>
      ) : ''}
      <div ref={ref}
          className={`${className} mt-2 w-full h-fit flex gap-2 md:gap-3 lg:gap-4 whitespace-nowrap ${black ? 'bg-black' : 'bg-lightBlack'} rounded-xl
            ${locked ? 'opacity-50' : ''}`}>
        <div ref={dropDownRef}
            className={`relative inline-block w-full sm:min-w-[12.5rem] md:min-w-[11.25rem] lg:min-w-[14.375rem]`}>
          <div className={`relative gap-1 sm:gap-3 flex items-center justify-between pt-[0.813rem] pb-[0.688rem] px-4 duration-300 cursor-pointer
                          ${locked ? 'pointer-events-none touch-none' : ''}`}
              onClick={() => setDropDownOpen(!dropDownOpen)}>
            {icon ? (
              <div className='w-fit h-fit absolute left-3 top-1/2 -translate-y-1/2'>
                {icon}
              </div>
            ) : ''}
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-xs sm:text-sm !leading-4 tracking-[0.009rem] text-white sm:ms-2 align-middle ${icon ? 'pl-6' :'' }`}>
                {currentOption.label}
              </p>
            </div>
            {locked ? (
              <FaLock className='w-[12px]'/>
            ) : (
              <span className={`${dropDownOpen ? "open rotate-[180deg]" : ""}`}>
                <DownSelectArrow />
              </span>
            )}
          </div>
          {dropDownOpen && (
            <div className="absolute left-0 top-11 z-50 bg-lightBlack bg-no-repeat bg-cover w-full rounded-md">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.1] overflow-hidden">
                {options.map((option, index) => (
                  <div
                    key={`${option.label}-${index}`}
                    onClick={() => {
                      setDropDownOpen(false);
                      handleOnChange(option.value);
                    }}
                    className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5">
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SkeletonDark className={`w-full sm:min-w-[12.5rem] md:min-w-[11.25rem] lg:min-w-[14.375rem] rounded-xl`}>
      <input type="text" className='opacity-0 pointer-events-none p-2  outline-none font-compact font-thin'/>
    </SkeletonDark>
  );
});

export default DropDown;
